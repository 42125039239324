import { useMemo, useState } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import { Column } from '../../../components/InfoTable';
import { useTranslation } from '../../../../services/translation';
import { useLibbyCall } from '../../../../hooks';
import { LibbyObject } from '../../../../types/types';
import { FilterBarSelection } from '../../../Reporting/routes/ReportingList/FilterBar';
import { endDateFormat, startDateFormat } from '../../../../utils';
import { ScreenAligned } from '../../../../components/ScreenAligned/ScreenAligned';
import { ButtonDetailsType } from '../../../../types/Button';
import { ButtonComponent } from '../../../../components/Button';
import { FilterStockDaysReport, SCREENS } from '../../../../components/FilterManager';
import { Filter } from '../../../../interfaces/business';
import StockDaysReportListLogic from './StockDaysReportListLogic';
import ResportingSalesListConst from './StockDaysReportListConst';
import InfoTable from '../../../components/InfoTable';
import LoadingData from 'routes/components/LoadingData';
import { orderBy as orderByFunction } from 'lodash';

export type StockDaysReportProps = {
  vatEnabled?: boolean;
  libby: LibbyObject;
  typeReporting: 'Stock Days' | 'Orders';
  daoNameHistory: string;
  daoName: string;
  columnsAux: Array<Column>;
  columnsProductAux: Array<Column>;
  onSortChange?: (orderBy: string, direction: 'asc' | 'desc') => void;
  orderBy?: string;
};

export const filterInit: FilterBarSelection = {
  marketplaces: [],
  courier: [],
  from: startDateFormat(moment().subtract('1', 'days')).toDate(),
  to: endDateFormat(moment()).toDate(),
  canal: [],
  company: [],
  category: [],
  brand: [],
  stock: ''
};

export const initFilter: Filter<FilterBarSelection> = {
  name: '',
  private: false,
  preset: false,
  account: { username: '', account_id: '-1' },
  screen: { screen_id: SCREENS.REPORTING_STOCK_DAYS.toString(), name: '' },
  last_update: new Date(),
  metadata: filterInit,
  campaign: false
};

const StockDaysReportListRaw = ({ libby, typeReporting = 'Stock Days', daoName = 'ster_stock_days_report' }: StockDaysReportProps) => {
  const { t } = useTranslation();

  const [direction, setDirection] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState('current_stock');

  const { columns } = ResportingSalesListConst();

  const { data, recall, working } = useLibbyCall(libby, {
    daoName,
    methodName: 'getByDate',
    params: [{ ...filterInit, direction, orderBy }]
  });

  const reportRows = useMemo(() => {
    const parsedData = data.length
      ? data.map((row: any) => ({
          sku: row.sku,
          name: row.name,
          current_stock: parseInt(row.odoo_stock),
          units_sold: parseInt(row.quantity),
          total_sold: parseInt(row.subtotal),
          valued_stock: 'A definir',
          stock_days: parseInt(row.stock_days),
          units_sold_stock_days: row.quantity > 0 && row.stock_days > 0 ? Math.round(row.quantity / row.stock_days) : 0
        }))
      : [];
    return orderByFunction(parsedData, [orderBy], [direction]);
  }, [data, orderBy, direction]);

  const workingData = useMemo(() => typeof working === 'boolean' && working, [working]);

  const [filter, setFilter] = useState<Filter<FilterBarSelection>>(initFilter);
  const { buttons } = StockDaysReportListLogic({
    recall,
    filter,
    setFilter,
    workingData
  });
  const handleRequestSort = (newOrderBy: string, newDirection: 'asc' | 'desc') => {
    setOrderBy(newOrderBy);
    setDirection(newDirection);
  };
  return (
    <ScreenAligned
      title={typeReporting}
      additionalTitle={
        <>
          {buttons.map((dataButton: ButtonDetailsType) => (
            <ButtonComponent
              key={dataButton.id}
              title={dataButton.title}
              className={dataButton.className}
              variant={dataButton.variant}
              color={dataButton.color}
              onClick={dataButton.onClick}
              type={dataButton.type}
              disabled={dataButton.disabled}
              loading={dataButton.loading}
            />
          ))}
        </>
      }
    >
      <Grid container direction="row" justify="center" alignItems="center">
        <FilterStockDaysReport initialFilter={initFilter} filter={filter} onFilter={setFilter} initExpanded working={workingData} withSaved />

        <InfoTable rows={reportRows} columns={columns} working={workingData} onBottomScroll={() => {}} rowIdKey="transportation_company_id" onSortChange={handleRequestSort} orderBy={orderBy} direction={direction} />
        <LoadingData label={`${t('Loaded registers')}`} working={working} cant={reportRows.length} />
      </Grid>
    </ScreenAligned>
  );
};

// El dao a usar debe de heredar de LibbyFetchDAO para que funcione
export const StockDaysReportList = DatabaseConnector(StockDaysReportListRaw)('ster_stock_days_report');
