import { Box } from '@material-ui/core';
import React from 'react';
import { Dispatch } from '../../../interfaces/business';
import { formatMoney, translate, capitalize, formatDate } from '../../../utils';

export const columns = [
  {
    id: 'send',
    label: '',
    noSort: true
  },
  {
    id: 'dispatch_id',
    label: 'ID',
    orderById: 'dispatch_id'
  },
  {
    id: 'total_amount_dispatch',
    label: 'Total amount of dispatch',
    render: ({ total_amount_dispatch }: any) => <Box>{!!total_amount_dispatch && formatMoney(total_amount_dispatch)}</Box>,
    noSort: true
  },
  {
    id: 'state',
    label: 'State',
    style: {
      whiteSpace: 'nowrap'
    },
    orderById: 'dispatch_state.name'
  },
  {
    id: 'created_at',
    label: 'Creation date',
    style: {
      whiteSpace: 'nowrap'
    },
    orderById: 'created_at'
  },
  {
    id: 'name',
    label: 'Name',
    orderById: 'name',
    style: {
      minWidth: '200px',
      whiteSpace: 'nowrap'
    }
  },
  {
    id: 'courier',
    label: 'Courier',
    style: {
      minWidth: '150px'
    },
    orderById: 'courier_service.courier.name'
  },
  {
    id: 'courier_service',
    label: 'Service',
    style: {
      minWidth: '150px'
    },
    orderById: 'courier_service.name'
  },
  {
    id: 'number_items',
    label: 'Orders',
    orderById: 'number_items'
  },
  {
    id: 'number_delivered',
    label: 'Delivered',
    noSort: true
  },
  {
    id: 'dispatch_zone',
    label: 'Dispatch Zone',
    width: '180px',
    style: {
      minWidth: '180px'
    },
    orderById: 'dispatch_zone.name'
  },
  {
    id: 'delivery_date',
    label: 'Delivery date',
    style: {
      whiteSpace: 'nowrap'
    },
    orderById: 'delivery_date'
  },
  {
    id: 'updated_by',
    label: 'Updated by',
    style: {
      whiteSpace: 'nowrap'
    },
    orderById: 'updated_by'
  },
  {
    id: 'updated_at',
    label: 'Change date',
    style: {
      whiteSpace: 'nowrap'
    },
    orderById: 'updated_at',
    render: ({ updated_at }: Dispatch) => <Box>{!!updated_at && formatDate(updated_at, true, false)}</Box>
  }
];

export const columnsNotInquiry = columns.filter((columnNotInquiryRaw) => columnNotInquiryRaw.id !== 'number_delivered');

const columnsInquiryRaw = [...columns];

export const columnsInquiry = columnsInquiryRaw;

export const columnsDetail = [
  {
    id: 'order_id',
    label: 'Order ID',
    style: {
      whiteSpace: 'nowrap'
    }
  },
  {
    id: 'so_number',
    label: 'Number so',
    style: {
      whiteSpace: 'nowrap'
    }
  },
  {
    id: 'province',
    label: 'Province',
    style: {
      whiteSpace: 'nowrap'
    }
  },
  {
    id: 'buyer',
    label: 'Buyer',
    style: {
      whiteSpace: 'nowrap'
    }
  },
  {
    id: 'state',
    label: 'Order State',
    width: '180px',
    style: {
      minWidth: '160px'
    },
    render: (value: any) => capitalize(translate(value?.state)) || ''
  },
  {
    id: 'items',
    label: 'Items',
    noSort: true,
    style: {
      whiteSpace: 'nowrap'
    }
  },
  {
    id: 'total',
    label: 'Total Purchase',
    width: '180px',
    style: {
      minWidth: '180px'
    },
    render: (value: any) => formatMoney(value?.total) || '-'
  },
  {
    id: 'collect',
    label: 'Collect id',
    style: {
      whiteSpace: 'nowrap'
    }
  },
  {
    id: 'updated_by',
    label: 'Updated By',
    style: {
      whiteSpace: 'nowrap'
    },
    render: (value: any) => (value.updated_by ? value.updated_by : '')
  },
  {
    id: 'dispatch_item_state',
    label: 'State',
    style: {
      whiteSpace: 'nowrap'
    },
    render: (value: any) => translate(value.dispatch_item_state) || ''
  },
  {
    id: 'delete',
    label: 'Actions',
    noSort: true,
    style: {
      whiteSpace: 'nowrap'
    }
  }
];

const columnsDetailInquiryRaw = [...columnsDetail];

columnsDetailInquiryRaw.splice(-1, 1);

export const columnsDetailInquiry = columnsDetailInquiryRaw;
