/* eslint-disable max-len */
export default {
  system: 'sistema',
  Menu: 'Menú',
  'Our Conferences': 'Nuestras Conferencias',
  'Network Error': 'Error de conexión',
  Conferences: 'Conferencias',
  Catalog: 'Catalogo',
  Export: 'Exportar',
  Dispatch: 'Despacho',
  'Old Shipping Addresses': 'Direccion de envio antigua',
  'Old Billing Addresses': 'Direccion de facturacion antigua',
  'Billing Addresses': 'Direccion de facturacion',
  'Upload your image in JPG, JPEG, PNG, or WEBP format, with a resolution of up to 500 pixels on each side and a maximum size of 10 MB':
    'Subí tu imagen en JPG, JPEG, PNG o WEBP, con una resolución mínima de 500 píxeles en ambos lados y hasta 10 MB de peso',
  Transport: 'Transporte',
  'Update Dispatch': 'Actualizar Despacho',
  'Dispatch saved': 'Despacho guardado',
  'Edit Dispatch': 'Editar Despacho',
  'Dispatch Inquiry': 'Consulta de Despachos',
  'Dispatches Inquiry': 'Consulta de Despachos',
  'Dispatch Detail': 'Detalle del Despacho',
  'Create Dispatch': 'Crear Despacho',
  'Order id': 'Id de Orden',
  'Total days': 'Días totales',
  'Days per area': 'Días por área',
  'Total Price': 'Precio Total',
  'Transport Details': 'Detalles del Transporte',
  'Error: error in odoo, try later': 'Ocurrio un error buscando informacion en Odoo, intenta mas tarde.',
  'Error: order is too old': 'La orden seleccionada no posee tier asignado. Para este caso deberá de generar una nueva orden.',
  Delete: 'Borrar',
  Settings: 'Ajustes',
  'of total orders': 'del total de órdenes',
  'Status according to time since the order was created': 'Estado según tiempo desde creación de la orden',
  'State according to time in each area': 'Estado según tiempo en cada área',
  Logout: 'Cerrar Sesión',
  'Aging report': 'Antigüedad de órdenes',
  'ID Order': 'ID de Orden',
  'Detail by orders': 'Detalle por órdenes',
  'Generate Report': 'Generar Reporte',
  'CSV Delimiter': 'Delimitador CSV',
  'What delimiter does the uploaded file use?': '¿Qué delimitador utiliza el archivo cargado?',
  Delimiter: 'Delimitador',
  Institute: 'Instituto',
  Login: 'Iniciar Sesión',
  English: 'Inglés',
  Spanish: 'Español',
  Conference: 'Conferencia',
  'CSV Export': 'Exportación CSV',
  "To print the tags you must upload the file to Andreani's portal.": 'Para imprimir las etiquetas debe subir el archivo al portal de Andreni.',
  Search: 'Buscar',
  Reset: 'Reiniciar',
  'Delimiter Required': 'Delimitador Requerido',
  'Start Date': 'Fecha desde',
  'Enter a type': 'Ingrese un tipo',
  'End Date': 'Fecha hasta',
  'New Conference': 'Nueva Conferencia',
  'Create it': 'Crear',
  'Number of': 'Cantidad de',
  'Rows per page:': 'Filas por página:',
  'Log ID': 'ID de Historial',
  'Order Number': 'Número de Orden',
  Sales: 'Ventas',
  'Old State Record': 'Viejo Estado',
  'New State Record': 'Nuevo Estado',
  'Modded From': 'Modificado Desde',
  'Created At': 'Fecha del Cambio',
  'Order Logs': 'Historial de Orden',
  Logs: 'Historial',
  'Order time report': 'Reporte de tiempos de órdenes',
  Orders: 'Órdenes',
  Name: 'Nombre',
  Lastname: 'Apellido',
  Source: 'Origen',
  State: 'Estado',
  Order: 'Orden',
  Pulled: 'Retirado',
  Match: 'Coincidencia',
  Close: 'Cerrar',
  'Update Location': 'Actualizar Localidad',
  'Edit Location': 'Editar Localidad',
  'Create Location': 'Crear Localidad',
  'State Name': 'Nombre de la provincia',
  'Province Name': 'Nombre de la provincia',
  'City Name': 'Nombre de la localidad',
  'City Information': 'Información de la localidad',
  'State Information': 'Información provincial',
  'City Matchs': 'Partidos de la provincia',
  'State Matchs': 'Coincidencias provinciales',
  'Cities List': 'Listado de Localidades',
  Cities: 'Ciudades',
  Evaluated: 'Evaluada',
  OK: 'OK',
  Province: 'Provincia',
  'Province Id': 'Id de Provincia',
  Problem: 'Con Problemas',
  'Manually Fixed': 'Corregida manualmente',
  Provinces: 'Provincias',
  Created: 'Creado',
  Sold: 'Vendido',
  'Location updated': 'Localidad Actualizada',
  'Location created': 'Localidad Creada',
  'On queue': 'En cola',
  'Number of Documents': 'Cantidad de documentos',
  'Number of Items': 'Cantidad de ítems',
  'Waiting for preparation': 'Esperando Preparación',
  'Waiting For Preparation': 'Esperando Preparación',
  Prepared: 'Preparado',
  Documents: 'Documentos',
  'Ready for delivery': 'Listo para la entrega',
  'Ready For Delivery': 'Listo para la entrega',
  'Out for delivery': 'En camino',
  'Out For Delivery': 'En camino',
  Delivered: 'Entregado',
  Cancelled: 'Cancelado',
  Production: 'Producción',
  Settled: 'Liquidado',
  Reconciled: 'Conciliado',
  'Ready to resend': 'Listo para reenviar',
  'Ready To Resend': 'Listo para reenviar',
  'Ready to Resend': 'Listo para reenviar',
  'Do you want to accept the order?': '¿Quieres aceptar la orden?',
  'Reject Order': 'Rechazar Orden',
  'Do you want to reject the order?': '¿Quieres rechazar la orden?',
  'Do you want to process the import?': '¿Querés procesar la importación?',
  Date: 'Fecha',
  Participants: 'Participantes',
  'Delete File': 'Borrar archivo',
  'Do you want to delete this file?': '¿Quieres borrar este archivo?',
  Description: 'Descripción',
  'Person to visit': 'Persona a visitar',
  'Please fill out this field.': 'Por favor complete este campo.',
  'The value must be a positive number': 'El valor debe ser un número positivo',
  'Edit Conference': 'Editar Conferencia',
  'Save it': 'Guardar',
  'Sign in': 'Entrar',
  'Sign In': 'Entrar',
  "Don't have an account? Sign Up": '¿No tenés una cuenta? Registrate',
  'Already have an account? Sign in': '¿Ya tenés una cuenta? Inicia Sesión',
  'Sign up': 'Registrar',
  'Sign Up': 'Registrar',
  Enroll: 'Inscribirse',
  'Do you want to enroll to': '¿Querés inscribirte a',
  Profile: 'Perfil',
  'Request failed with status code 404': 'Falló de Petición: 404.',
  'Request failed with status code 400': 'Falló de Petición: 400.',
  Email: 'Correo Electrónico',
  Password: 'Contraseña',
  'The provided authorization grant (e.g., authorization code, resource owner credentials) or refresh token is invalid, expired, revoked, does not match the redirection URI used in the authorization request, or was issued to another client.':
    'Credenciales Inválidas',
  'Available positions': 'Cupo disponible',
  'Signed Users': 'Usuarios Inscriptos',
  'First Name': 'Nombre',
  'Saved files': 'Archivos guardados',
  'Updated status': 'Estado actualizado',
  'Deleted file': 'Archivo borrado',
  'Import created': 'Importación creada',
  'Last Name': 'Apellido',
  'Repeat Password': 'Repita la Contraseña',
  'Passwords must match!': 'Las Contraseñas deben coincidir!',
  'The email has already been taken.': 'El Correo Electrónico ingresado ya existe.',
  'The value must be a valid email': 'El valor debe ser un Correo Electrónico válido',
  'Remove Confirmation': 'Confirmación de Eliminación',
  'Do you want to remove': '¿Querés eliminar',
  'Remove it': 'Eliminar',
  No: 'No',
  Saved: 'Guardado',
  'SQLSTATE[22P02]: Invalid text representation: 7 ERROR:  invalid input syntax for integer: "new" (SQL: select * from "conferences" where "id" = new limit 1)':
    'Se produjo un error SQLSTATE[22P02] al generar o editar un registro. Posiblemente haya un dato vacío o mal ingresado. Por favor, vuelva a intentar ingresarlo en unos minutos.',
  'SQLSTATE[22P02]: Invalid text representation: 7 ERROR:  invalid input syntax for integer: "[object Object]" (SQL: select * from "conferences" where "id" = [object Object] limit 1)':
    'Se produjo un error SQLSTATE[22P02] al editar un registro. El objeto que se quería modificar no existe o no se encuentra. Posiblemente haya sido dado de baja o cambiado de estado. Por favor, vuelva a intentarlo en unos minutos.',
  'Permission denied!': '¡Permiso Denegado!',
  'Sign Up Confirmation': 'Confirmación de Inscripción',
  'Do you want to sign up to': '¿Querés inscribirte a',
  'Unsubscribe Confirmation': 'Confirmación de Desinscripción',
  'Do you want to unsubscribe': '¿Querés desinscribirte de',
  Unsubscribe: 'Desinscribir',
  Undefined: 'Indefinido',
  'Unauthenticated.': 'No autenticado.',
  'SQLSTATE[22P02]: Invalid text representation: 7 ERROR:  invalid input syntax for integer: "undefined" (SQL: select * from "conferences" where "id" = undefined limit 1)':
    'Se produjo el error SQLSTATE[22P02]. El dato no se ingresó correctamente y no es posible autentificarse. Por favor, vuelva a ingresarlo en unos minutos.',
  'Expired conference': 'Conferencia expirada',
  'Do you want to remove your inscription for': '¿Querés desinscribirte de ',
  'No query results for model [App\\Conference].': 'No hay datos para mostrar',
  'Upload File': 'Subir Archivo',
  'Select the source': 'Seleccione la fuente',
  'Select Files': 'Seleccionar Archivos',
  'The name field is required.': 'El campo Nombre es requerido.',
  'This conference already has full places': 'La conferencia no tiene plazas disponibles',
  'Visits List': 'Listado de Visitas',
  'People List': 'Listado de Personas',
  'No match': 'Sin coincidencias',
  Loading: 'Cargando',
  People: 'Personas',
  Visits: 'Visitas',
  Username: 'Nombre de usuario',
  Locations: 'Ubicaciones',
  Localities: 'Localidades',
  User: 'Usuario',
  user: 'usuario',
  Type: 'Tipo',
  Capture: 'Captura',
  'Invalid user': 'Usuario inválido',
  OverMAXPercentage: 'El porcentaje supera el maximo recomendado y sera evaluado',
  MAXPercentage: 'Descuento maximo alcanzado',
  percentageExplained: 'descuento a otorgar',
  'Philsen App': 'Aplicación Philsen',
  'New Visit': 'Nueva Visita',
  Document: 'Documento',
  Restore: 'Restaurar',
  Scan: 'Escanear',
  Dashboard: 'Tablero',
  Genre: 'Genero',
  Logistics: 'Logística',
  Operations: 'Operaciones',
  'Birth Date': 'Fecha de Nacimiento',
  'Card Number': 'Número de Tarjeta',
  Cancel: 'Cancelar',
  Save: 'Guardar',
  Female: 'Femenino',
  Male: 'Masculino',
  All: 'Todos',
  'Date should not be before minimal date': 'La fecha no debe ser anterior a la fecha mínima',
  'Date should not be after maximal date': 'La fecha no debe ser posterior a la fecha máxima',
  'Something is wrong': 'Algo salió mal',
  'Something went wrong': 'Algo salió mal',
  'Product $$$$ edited successfully': 'Producto $$$$ editado con exito',
  'Product created successfully': 'Producto creado con exito',
  'Please try again later': 'Por favor vuelva a intentarlo más tarde',
  'Visit saved': 'Visita guardada satisfactoriamente',
  'Searching Person': 'Buscando persona',
  Yes: 'Si',
  'Confirm new import': 'Confirmar nueva importación',
  'Change Password': 'Cambiar Contraseña',
  'Export Orders': 'Exportar Órdenes',
  'New external data will be imported into the system, are you sure to proceed with type $$$$?': 'Se importaran nuevos datos externos al sistema.¿Está seguro de proceder con el tipo $$$$?',
  'Do you want to change the account password of the user $$$$?': '¿Quieres cambiar la contraseña del usuario $$$$?',
  'Do you want to change the account role of the user $$$$?': '¿Quieres cambiar el rol del usuario $$$$?',
  'Do you want to change the account name of the user $$$$?': '¿Quieres cambiar el nombre del usuario $$$$?',
  'Do you want to disable the account of the user $$$$?': '¿Quieres deshabilitar al usuario $$$$?',
  'Do you want to enable the account of the user $$$$?': '¿Quieres habilitar al usuario $$$$?',
  Role: 'Rol',
  Administrator: 'Administrador',
  'No Role': 'Sin rol',
  Disable: 'Deshabilitar',
  Enable: 'Habilitar',
  Enabled: 'Habilitado',
  Disabled: 'Deshabilitado',
  Accounts: 'Cuentas',
  Status: 'Estado',
  Create: 'Crear',
  'Creation Day': 'Fecha de Creación',
  'Import Type': 'Tipo de Importación',
  'Import type selection': 'Selección de tipo de importación',
  'Select type of import': 'Seleccione el tipo de importación',
  'Enable Account': 'Habilitar Cuenta',
  'Disable Account': 'Deshabilitar Cuenta',
  'Search User': 'Buscar usuario',
  'Check Availability': 'Verificar Disponibilidad',
  'User already exists': 'El usuario ya existe',
  'Create Account': 'Crear Cuenta',
  'No coincidences': 'Sin coincidencias',
  'Create Import': 'Crear Importación',
  'Imports List': 'Listado de Importaciones',
  'Orders List': 'Listado de Órdenes',
  'Inbox Orders List': 'Listado de Órdenes de Entrada',
  'Orders Entry': 'Entrada de Órdenes',
  'Inbox Order State ID': 'ID de Estado de Orden de Entrada',
  'Inbox Order': 'Orden de Entrada',
  Inbox: 'Entradas',
  Reference: 'Referencia',
  Imports: 'Importaciones',
  Import: 'Importación',
  'File deleted successfully': 'Archivo eliminado exitoxamente',
  'Updated Password': 'Contraseña Actualizada',
  'Updated Username': 'Nombre de Usuario Actualizado',
  'Updated Role': 'Rol Actualizado',
  'Uploaded file successfully': 'Archivo subido exitoxamente',
  Matches: 'Coincidencias',
  'comma delimited': 'delimitados por coma',
  Update: 'Actualizar',
  'Disabled User': 'Usuario Deshabilitado',
  'Upload Files': 'Cargar Archivos',
  'Process Import': 'Procesar importación',
  Detail: 'Detalle',
  Accept: 'Aceptar',
  Owner: 'Propietario',
  'Failed to delete': 'No se pudo borrar',
  'Uploaded Files': 'Archivos cargados',
  'Save Files': 'Guardar Archivos',
  'Pending Processing': 'Procesamiento pendiente',
  Pending: 'Pendiente',
  Processing: 'Procesando',
  'Pending approbation': 'Pendiente de aprobación',
  Accepted: 'Aceptado',
  Rejected: 'Rechazado',
  Failure: 'Error',
  Example: 'Ejemplo',
  Example2: 'Ejemplo2',
  'Last Update': 'Última modificación',
  'Cannot upload file, reason:': 'No se pudo subir el archivo, razon:',
  Liquidations: 'Liquidaciones',
  'Orders processed:': 'Ordenes procesadas:',
  'Unprocessed Orders:': 'Ordenes no procesadas',
  'Loaded liquidations': 'Liquidaciones cargadas',
  'Marketplace ID': 'Id del Marketplace',
  Amount: 'Monto',
  Tax: 'Impuesto',
  Exit: 'Salir',
  Total: 'Total',
  'Unprocessed Total': 'Total sin Procesar',
  created: 'Creado',
  Selled: 'Vendido',
  Paid: 'Cobrado',
  'Upload to collections': 'Subido a cobranzas',
  'waiting for preparation': 'Esperando preparación',
  Onqueue: 'En Cola',
  'ready for delivery': 'Listo para la entrega',
  'out for delivery': 'En camino',
  delivered: 'Entregado',
  cancelled: 'Cancelado',
  'Order Detail': 'Detalle de la Órden',
  'Basic Information': 'Información Básica',
  'No specification': 'Sin especificar',
  'Product Type': 'Tipo de producto',
  Tag: 'Etiqueta',
  Attributes: 'Atributos',
  'Other Information': 'Otra Información',
  Width: 'Ancho',
  Height: 'Alto',
  Depth: 'Profundidad',
  Weight: 'Peso',
  Buyer: 'Comprador',
  'Document Type': 'Tipo de Documento',
  'Phone Number': 'Número de Teléfono',
  Address: 'Dirección',
  'Zip Code': 'Código Postal',
  'Shipment Cost': 'Costo de envío',
  Ster: 'Ster',
  Business: 'Comercial',
  Marketplace: 'Marketplace',
  Shipment: 'Envío',
  Service: 'Servicio',
  Courier: 'Mensajería',
  'Shipment Address': 'Dirección de envío',
  Comments: 'Comentarios',
  'Add Comment': 'Comentar',
  'New Comment': 'Nuevo Comentario',
  'Comment saved': 'Comentario Guardado',
  'Comment ID': 'Comentario ID',
  Receiver: 'Receptor',
  Tracking: 'Seguimiento',
  Items: 'Ítems',
  Sku: 'Sku',
  Zip: 'Código Postal',
  Quantity: 'Cantidad',
  Price: 'Precio',
  Subtotal: 'Subtotal',
  Number: 'Número',
  Location: 'Localidad',
  'Creation Date': 'Fecha de Creación',
  Link: 'Vínculo',
  'Owner ID': 'ID del Propietario',
  'Go to file': 'Ir al archivo',
  Phone: 'Teléfono',
  Actions: 'Acciones',
  'Made in': 'Hecho en',
  action: 'acción',
  Action: 'Acción',
  Where: 'Dónde',
  'Track Information': 'Información de rastreo',
  'Affiliate ID': 'ID de afiliado',
  'Authorized Date': 'Fecha de autorización',
  'Client ID': 'ID del cliente',
  'Client email': 'Email del cliente',
  'Client phone': 'Teléfono del cliente',
  'Follow-up Email': 'Email de seguimiento',
  'Checked-in': 'Chequeo de Ingreso',
  Completed: 'Completado',
  'Marketplace URL': 'URL del Marketplace',
  'Status description': 'Descripción del estado',
  'Generated Order Detail': 'Detalles de la Orden Generada',
  'The edition will be mark this record to be reprocessed. Do you want to proceed?': 'La edición marcará este registro para ser reprocesado. ¿Quiere proceder?',
  Proceed: 'Proceder',
  'Edition Confirmation': 'Confirmación de Edición',
  ID: 'ID',
  'Import type': 'Tipo de Importación',
  'Market place': 'Marketplace',
  'Owner id': 'ID del Propietario',
  Results: 'Resultados',
  Row: 'Fila',
  Message: 'Mensaje',
  Success: 'Exitosos',
  Fails: 'Fallidos',
  Errors: 'Errores',
  'Order ID': 'Id de orden',
  'Other fields': 'Otros filtros',
  'Import ID': 'Id de importación',
  Metrics: 'Métrica',
  'Order Imports': 'Importaciones de Órdenes',
  Clean: 'Limpiar',
  'Inbox ID': 'ID de orden',
  'City ID': 'ID de ciudad',
  'State ID': 'ID de provincia',
  'Location ID': 'ID de Localidad',
  'Province ID': 'ID de Provincia',
  'NOTIFY MARKETPLACE': 'NOTIFICAR MARKETPLACE',
  'PERSIST ORDER': 'PERSISTIR ORDEN',
  'PROCESS ITEMS': 'PROCESAR ÍTEM',
  'LOOK FOR STATUS': 'BUSCAR POR ESTADO',
  'LOOK FOR COURIER': 'BUSCA POR CORREO',
  'LOCATION:LOOK FOR CITY': 'UBICACIÓN: BUSCAR POR LOCALIDAD',
  'LOCATION:LOOK FOR PROVINCE': 'UBICACIÓN: BUSCAR POR PROVINCIA',
  'PROCESS SHIPMENT': 'PROCESAR ENVÍO',
  'LOOK FOR DOCUMENT TYPE': 'BUSCAR POR TIPO DE DOCUMENTO',
  'PROCESS BUYER': 'PROCESAR COMPRADOR',
  'LOOK FOR SOURCE': 'BUSCAR POR FUENTE',
  'GRAB DATA FROM SERVER': 'OBTENER DATOS DEL SERVIDOR',
  'start-handling': 'iniciar-manejo',
  ORDER: 'ÓRDEN',
  SHIPPING: 'ENVÍO',
  'Province updated': 'Provincia actualizada',
  BUYER: 'COMPRADOR',
  'Request user': 'Solicitar usuario',
  'Please wait for Admin approval': 'Espere a que el administrador apruebe',
  'Account ID': 'ID de la cuenta',
  'Accounts List': 'Listado de Cuentas',
  Shipments: 'Envíos',
  'Edit Province': 'Editar provincia',
  Printing: 'Impresión',
  Shipping: 'Envíos',
  Edit: 'Editar',
  localities: 'localidades',
  Domicile: 'Domicilio',
  Print: 'Imprimir',
  'Order status': 'Estado de la orden',
  'Printing status': 'Estado de impresión',
  'Shipment id': 'Id de envío',
  Seller: 'Vendedor',
  Sender: 'Remitente',
  'Customer name': 'Nombre del cliente',
  Observation: 'Observación',
  'No description': 'Sin descripción ',
  Product: 'Producto',
  'Label to stick on the package': 'Etiqueta para pegar en el paquete',
  'Information for assembling the package': 'Información para el armado del paquete',
  'Order and Order Shipment state has changed': 'Los estados de la orden y la orden de entrega han cambiado',
  'Delivery Confirmation': 'Confirmación de Entrega',
  Send: 'Enviar',
  'Confirm if the order number': 'Confirme si la orden número',
  'was delivered': 'fue entregada',
  'The status of the order was modified ': 'El status de la orden fue modificado',
  'This order already has status out for delivery': 'Este pedido ya tiene el estado fuera para la entrega',
  'Shipping data': 'Datos de envío',
  'Customer code': 'Código de cliente',
  Customer: 'Cliente',
  Recipient: 'Destinatario',
  Department: 'Departamento',
  Printed: 'Impreso',
  'Not printed': 'No impreso',
  Returned: 'Devuelto',
  'Updated by': 'Actualizado por',
  'Origin date': 'Fecha de Origen',
  'Change date': 'Fecha de Cambio',
  'Invalid date': 'Fecha Inválida',
  'Notify Bill': 'Notificar Factura',
  'Notify Bill and Tracker': 'Notificar Factura y Remito',
  'Notify Tracker': 'Notificar Remito',
  'Status changed': 'Estado cambiado',
  Confirmation: 'Confirmación',
  'Do you want set the status to': '¿Quieres cambiar el estado a',
  'Bill informed': 'Factura informada',
  'Tracker informed': 'Remito informado',
  'Bill and Tracker Informed': 'Factura y Remito Informados',
  'Set it': 'Cambiarlo',
  System: 'Sistema',
  'Number so': 'Número de SO',
  'Order Comment': 'Comentario de la orden',
  Dispatches: 'Despachos',
  'Please select an item in the list.': 'Seleccione un elemento de la lista.',
  selled: 'Vendido',
  'not set': 'no establecido',
  'Magento-OrderPuller': 'Magento-Extractor de pedidos',
  From: 'Desde',
  To: 'Hasta',
  'All the marketplaces': 'Todos los mercados',
  Count: 'Conteo',
  'Orders by state': 'Órdenes por estado',
  scheduled: 'programado',
  preparation: 'preparacion',
  'out to delivery': 'a la entrega',
  'Sale Order': 'Orden de venta',
  'Delivery Note': 'Remito',
  Bill: 'Factura',
  Receipt: 'Recibo',
  'ready to resend': 'Listo para reenviar',
  prepared: 'Preparado',
  onqueue: 'En cola',
  production: 'Producción',
  'upload to collections': 'subido a cobranzas',
  paid: 'Cobrado',
  returned: 'Devuelto',
  'bill informed': 'Factura informada',
  'tracker informed': 'Remito informado',
  filters: 'filtros',
  'Andreani shipments': 'Andreani envios',
  'Fravega shipments': 'Fravega envios',
  'Home delivery': 'Envíos a domicilio',
  'FravegaShipments - Home delivery': 'FravegaEnvios - Envio a domicilio',
  'Creation date': 'Fecha de Creación',
  'Delivery date': 'Fecha de Envío',
  'Do you want to delete the order of the dispatch $$$$?': '¿Querés eliminar la orden del despacho $$$$?',
  'Order Delete': 'Eliminación de orden',
  'Order assigned in another dispatch': 'Orden asignada en otro despacho',
  'There is no selected order': 'No hay un pedido seleccionado',
  "The order is canceled, can't be dispatched": 'La orden está cancelada, no se puede despachar',
  'The order is not in printed status': 'El pedido no está en estado impreso',
  'The order does not belong to the courier service': 'El pedido no pertenece al servicio de mensajería',
  Details: 'Detalles',
  'Are you sure to Change de Courier Service? If you proceed with this action you will be lost all the order data in this dispatch':
    '¿Está seguro de cambiar el servicio de mensajería? Si continúa con esta acción, perderá todos los datos relacionados con órdenes en este despacho',
  'Send Dispatch': 'Enviar despacho',
  'Search by ID': 'Buscar por ID',
  'You can not send a Dispatch without orders. Orders': 'No puede enviar un Despacho sin pedidos. Órdenes',
  'Are you sure to Cancel this Dispatch?, The Order Quantity is': '¿Está seguro de cancelar este envío?, La cantidad de pedido es',
  'Cancel Dispatch': 'Cancelar Despacho',
  Information: 'Información',
  'The action was cancelled, maybe by some error': 'La accion fue cancelada, puede que por algun error',
  'Dispath Cancelled': 'Despacho Cancelado',
  'Add Order': 'Agregar orden',
  'Assigned turn': 'Turno asignado',
  'The actual order belongs to $$$$': 'La orden pertenece a $$$$',
  'It is not possible to add the order': 'No es posible añadir la orden',
  'The order is assigned to the dispatch $$$$': 'La orden está asignado al despacho $$$$',
  'Order $$$$ is not registered': 'Orden $$$$ no está registrado',
  'Reason for cancellation': 'Razon de la Cancelación',
  'The order is not printed': 'La orden no está impresa',
  Sent: 'Enviado',
  'Saved changes': 'Cambios guardados',
  'Order delete': 'Eliminación de orden',
  'Are you sure to send this Dispatch?, The Order Quantity is': '¿Está seguro de enviar este Despacho?, La Cantidad de orden es',
  'It is about to dispatch $$$$ orders. Do you want to continue?': 'Está por despachar $$$$ órdenes. ¿Desea continuar?',
  'You are about to cancel a Dispatch with $$$$ orders, do you want to continue?': 'Está a punto de cancelar un despacho con $$$$ órdenes, ¿quieres continuar?',
  'Confirm Dispatch': 'Confirmar Despacho',
  Confirm: 'Confirmar',
  Add: 'Agregar',
  'Dispatch Sent': 'Despacho enviado',
  'Dispatch Cancelled': 'Despacho cancelado',
  'The order $$$ belongs to the courier $$$$': 'La orden $$$ pertenece a la mensajería $$$$',
  'It is not possible add the order $$$$': 'No es posible agregar la orden $$$$',
  'Dispatch ($$$$)': 'Despacho ($$$$)',
  Management: 'Gestión',
  Driver: 'Conductor',
  'External Driver': 'Conductor Externo',
  'ERP Orders': 'Órdenes ERP',
  'Sales Channel Name': 'Nombre Canal de Ventas',
  'Sales Channel Number': 'Numero Canal de Ventas',
  'Sales Team': 'Equipo de Ventas',
  'Invoice Date': 'Fecha de Factura',
  'Invoice Number': 'Número de Factura',
  'Client Name': 'Nombre Cliente',
  'Client Phone': 'Telefono Cliente',
  'Client Mobile': 'Movil Cliente',
  'Client Email': 'Email Cliente',
  'Client Address': 'Dirección Cliente',
  'Shipment Date': 'Fecha de Envio',
  'Shipment Tracking': 'Rastreo de Envio',
  'ID ML': 'ID ML',
  'Credit Note Number': 'Nro. Nota de Credito',
  'ERP Order': 'Orden ERP',
  'Serial Numbers': 'Numeros de Serie',
  Agent: 'Agente',
  Finance: 'Finanzas',
  Delivery: 'Entrega',
  Sale: 'Venta',
  Invoice: 'Factura',
  sent: 'Enviado',
  Reports: 'Reportes',
  'SO Name': 'Nombre SO',
  'So Name': 'Nombre SO',
  Frequency: 'Frecuencia',
  'Last execution': 'Última ejecución',
  'last states': 'Últimos estados',
  Metadata: 'Metadatos',
  Crashed: 'Colgado',
  'Process monitor': 'Monitor de procesos',
  'Change Role': 'Cambiar rol',
  Liquidation: 'Liquidación',
  'Liquidations Details': 'Detalle de Liquidación',
  Warning: 'Advertencia',
  Critical: 'Crítico',
  Client: 'Cliente',
  Ignored: 'Ignorada',
  Ignore: 'Ignorar',
  'New Liquidation': 'Nueva Liquidación',
  'Start of Period': 'Inicio del Período',
  'End of Period': 'Fin del Período',
  'Verify Liquidation': 'Verificar liquidación',
  'Error when trying to Liquidate': 'Error al intentar liquidar',
  'No orders to process': 'Sin ordenes para procesar',
  'The following orders were are already liquidated': 'Las siguientes ordenes ya fueron liquidadas',
  'Unprocessed Orders': 'Ordenes No Procesadas',
  'There are Unprocessed Orders': 'Hay ordenes no procesadas',
  'Unprocessed Orders Total': 'Total de ordenes sin procesar',
  'Total In Order': 'Total En Orden',
  'No matches were found for the orders in the imported file': 'No se encontraron coincidencias con las ordenes del archivo importado',
  Reprocess: 'Reprocesar',
  'The file extension must not be different from .csv': 'La extensión del archivo no debe ser diferente de .csv',
  'Ignored State Confirmation': 'Confirmación del Estado Ignorado',
  'You are about to pass the State to Ignored. Do you want to proceed?': 'Estas a punto de pasar al Estado Ignorado, ¿Quieres proceder?',
  'Saved, The Order Passed to Ignored': 'Guardado, La Order Paso a Ignorada',
  'Reprocess Confirmation': 'Confirmación de Reporcesar',
  'You are about to reprocess the order. Do you want to proceed?': 'Estas a punto de reprocesar la orden, ¿Quieres proceder?',
  'Saved, The Order will be reprocessed': 'Guardado, La orden sera reprocesada',
  'Status by area': 'Estado por área',
  'Status since creation': 'Estado desde creación',
  Area: 'Área',
  'Clear Filters': 'Limpiar Filtros',
  'Massive Uploads': 'Carga Masiva',
  'Uploads Delivered': 'Cargas Entregadas',
  'Zip List': 'Lista de Código Postales',
  Upload: 'Carga',
  City: 'Ciudad',
  'City Matches': 'Coincidencias por ciudad',
  'State Matches': 'Coincidencias por estado',
  'Loaded registers': 'Registros cargados',
  'Process quantity': 'Cantidad de procesos',
  'Pdf download': 'Descarga de PDF',
  'Downloading PDF': 'Descargando PDF',
  of: 'de',
  'All the courier': 'Todas las mensajerías',
  'Adding order: $$$$': 'Añadiendo orden: $$$$',
  'Removing order: $$$$': 'Removiendo  orden: $$$$',
  'Execution SP': 'Ejecución SP',
  'Created by': 'Creado por',
  'Assign Dispatch Zone': 'Asigne Zona de Despacho',
  Floor: 'Piso',
  'Location data': 'Datos de ubicación',
  'Report pending andreani income': 'Pendientes de ingreso en Andreani',
  'Courier state': 'Estado de mensajerías',
  'Courier detailed state': 'Estado detallado de mensajerías',
  'Dispatch date': 'Fecha de despacho',
  'Total Purchase': 'Total de la Compra',
  'Purchase Date': 'Fecha de la Compra',
  Priority: 'Prioridad',
  'Selected Orders': 'Órdenes Seleccionadas',
  'SO Number': 'Número de SO',
  'Dispatch Zone': 'Zona de Despacho',
  'Assigned Orders': 'Órdenes Asignadas',
  'Updated By': 'Modificado Por',
  'No Date Record': 'Sin Fecha Registrada',
  'Zone 1': 'Zona 1',
  'Zone 2': 'Zona 2',
  'Zone 3': 'Zona 3',
  Collect: 'Colecta',
  Assigned: 'Asignado',
  Observations: 'Observaciones',
  'Dispatch Name': 'Nombre del Despacho',
  'Orders Added': 'Órdenes Agregadas',
  PreparingPackage: 'Preparando Paquete',
  AssignedToCollect: 'Asignada a la Colecta',
  Collecting: 'Colectando',
  'Dispatch date from': 'Fecha de despacho desde',
  'Dispatch Date to': 'Fecha de despacho hasta',
  'Shipping Date': 'Fecha de Entrega',
  'Assigned To': 'Asignado a',
  Products: 'Productos',
  'Pick up': 'Pickear',
  Start: 'Comenzar',
  'Dispatch Zone $$$$ was able to use': 'La Zona de Despacho $$$$ esta disponible para su uso',
  "The dispatch zone $$$$ can't back able to use for some reason": 'Por alguna razon no se puede reestablecer el uso de la zona de Despacho $$$$',
  'Add collect': 'Agregar colecta',
  'Updated sku $$$ product': 'Producto del sku $$$ actualizado',
  'Save product': 'Guardar producto',
  'Updated product': 'Producto actualizado',
  'To generate the label please enter the measurements and weight of the product': 'Para generar la etiqueta por favor ingrese las medidas y peso del producto',
  'Width (cm)': 'Ancho (cm)',
  'Height (cm)': 'Alto (cm)',
  'Depth (cm)': 'Profundo (cm)',
  'Weight (grms)': 'Peso (grms)',
  'Name/Description': 'Nombre/Descripción',
  'Name/Sku': 'Nombre/Sku',
  'Pick the Cart Box and place in it': 'Pickea el Cajón y colocalo en el',
  'Cart Box Picked': 'Cajón pickeado',
  'Product Picked': 'Producto pickeado',
  Pick: 'Pickeá',
  'Incorrect cart box': 'Cajón incorrecto',
  'Incorrect product': 'Producto incorrecto',
  'The scanned cart box does not match the one requested. Check and rescan.': 'El cajón escaneado no coindice con el solicitado. Controlá y volvé a escanear.',
  'The scanned product does not match the requested one. Check and rescan.': 'El producto escaneado no coindice con el solicitado. Controlá y volvé a escanear.',
  'Error picking the Cart Box': 'Error pickeando el cajón',
  'Error picking the Product': 'Error pickeando el producto',
  'Critical amount of logistics': 'Cantidad crítica de logística',
  Collects: 'Colectas',
  'Logistics Operator': 'Operador de logística',
  Cart: 'Carro',
  'Assigned to': ' Asignado a',
  'Collect saved': 'Colecta guardada',
  'Modified collect': 'Colecta modificada',
  'Create Collect': 'Crear colecta',
  'Update Collect': 'Actualizar colecta',
  'Delete orders': 'Eliminar ordenes',
  'You want to remove the following $$$$ orders from the collect?': '¿Desea eliminar los siguientes órdenes $$$$ de la colecta?',
  'Order $$$$ removed': 'Orden $$$$ eliminada',
  'Do you want to delete the order of the collect $$$$?': '¿Desea eliminar la orden $$$$ de la colecta ?',
  'It has $$$$ positions available in the cart. You cannot select greater than the available quantity': 'Tiene disponible $$$$ posiciones en el carro. No puede seleccionar mayor a la cantidad disponible',
  Percentage: 'Porcentaje',
  'No collects pending': 'No hay colectas pendientes',
  'Delete collects': 'Eliminar colectas',
  'Collect id': 'Id de colecta',
  'Orders saved': 'Órdenes guardadas',
  'Order loaded to add in the collection': 'Orden cargadas para agregar en la colecta',
  'Delete selection': 'Eliminar seleccion',
  See: 'Ver',
  'Close collect': 'Cerrar colecta',
  'Collect Closed': 'Colecta cerrada',
  'Warning: The following processes should be checked as soon as possible.': 'Advertencia: Los siguientes procesos deben comprobarse lo antes posible.',
  'Name process': 'Nombre de procesos',
  'Error consulting process monitor alert.': 'Error al consultar alerta monitor de procesos.',
  'Data failed to display alert modal': 'Error con los datos al mostrar modal de alerta',
  'You cannot select the number of orders greater than the number of available items in the cart.': 'No se puede seleccionar cantidad de órdenes mayores al numero de posiciones disponible del carro.',
  'Pick up the Serial of': 'Pickéa el Serial de',
  'Pick up the SKU of': 'Pickea el SKU de',
  'Pick up the product': 'Pickea el producto',
  'Without Info': 'Sin Información',
  Corridor: 'Pasillo',
  Shelves: 'Estantería',
  Shelf: 'Altura',
  Position: 'Ubicación',
  'Product Collected': 'Producto Colectado',
  'Order State': 'Estado de la Orden',
  Low: 'Baja',
  Medium: 'Media',
  High: 'Alta',
  'Not collected': 'No colectado',
  Collected: 'Colectado',
  "You haven't permissions to see this section.": 'No tienes permisos para ver esta sección.',
  "You haven't permissions to see this collect.": 'No tienes permisos para ver esta colecta.',
  'Let the Products in the Dispatch Zone.': 'Deja los productos en la zona de despacho',
  'All products have been collected. Close collect?': 'Se han recolectado todos los productos. ¿Cerrar colecta?',
  'You finished the Collect': 'Terminaste la colecta',
  'Please, leave the product of the order ($$$) in the dispatch zone ($$$$).': 'Por favor, deja el producto de la orden ($$$) en la zona de despacho ($$$$).',
  'Please, leave the products of the order ($$$) in the dispatch zone ($$$$).': 'Por favor, deja los productos de la orden ($$$) en la zona de despacho ($$$$).',
  'Pick and place it in box': 'Pickeá y colócalo en el cajón',
  Manifest: 'Manifiesto',
  'Invoice and Tracker': 'Factura y Remito',
  Labels: 'Etiquetas',
  Finish: 'Completar',
  'Please print documents and put them in its correct ubication': 'Imprima los documentos y colóquelos dentro de su ubicación correspondiente.',
  Reminder: 'Recordar',
  'Error deleting order': 'Error al eliminar la orden',
  Box: 'Cajón',
  Return: 'Devolver',
  '$$$ returned product': '$$$ producto devuelto',
  'Error returning product: $$$': 'Error al devolver producto',
  "There're products without location inf in this collect. Please notify to your supervisor or fill the Location info in the system.":
    'Hay productos en esta colecta sin información de ubicación. Notifique a su supervisor o complete la información de ubicación en el sistema.',
  'Without Location': 'Sin Ubicación',
  'Not Started': 'No iniciado',
  Complete: 'Completado',
  'All the status': 'Todo los estados',
  'From $fromItems items this will just take $toItems': 'De $fromItems ítems solo serán tomados $toItems ítems',
  'Serial number already used': 'Número de serie ya utilizado',
  'Please, validate the serial number and try again': 'Por favor, valide el número de serie e inténtelo de nuevo',
  'Error checking the serial number': 'Error chequeando el número de serie',
  'Information: You can then close it in the collect summary view via the "Close collect" button': 'Información: Puede cerrarla luego desde la vista del resumen de la colecta a través del botón "Cerrar colecta"',
  Undivided: 'Sin divisiones',
  'This order $$$ is already in $$$$ status, you are sure you want to remove it from dispatch?': 'Esta orden $$$ está en estado $$$$, usted está seguro de que desea eliminarlo de despacho?',
  'Cars are available below this selected amount.': 'Existen carros disponibles inferiores a esta cantidad seleccionada.',
  Capacity: 'Capacidad',
  'No cars available for capacity greater than $$$$.': 'No hay carros disponibles para capacidad mayor a $$$$.',
  'Error sending': 'Error al enviar',
  'Failed to cancel': 'Error al cancelar',
  'Error printing manifest': 'Error imprimiendo el manifiesto',
  'Do you want to print the manifest?': '¿Quieres imprimir el manifiesto?',
  'Print manifest': 'Imprimir manifiesto',
  'Serial number has no stock': 'Número de serie no tiene stock',
  'The cart $$$$ was able to use': 'El carro $$$$ esta disponible para su uso',
  'Collect cancelled': 'Colecta cancelada',
  'You are about to cancel a collect with $$$$ orders, do you want to continue?': 'Está a punto de cancelar una colecta con $$$$ órdenes, ¿quieres continuar?',
  'Cancel collect': 'Cancelar colecta',
  'the dispatch is not ready to be sent': 'el despacho no esta listo para ser enviado',
  'Error modifying collect': 'Error modificando colecta',
  'Error creating collect': 'Error creando colecta',
  'Error adding orders in dispatch': 'Error al agregar órdenes en despacho',
  'Logistics Manager': 'Jefe de Logística',
  'Please, delete all orders($$$$) with canceled status': 'Por favor, elimine las órdenes($$$$) con estado cancelado',
  'You have orders in a canceled state': 'Tiene órdenes en estado cancelado',
  'You can not send an office with canceled orders': 'No puede enviar un despacho con órdenes canceladas',
  'Order $$$ canceled': 'Orden $$$ cancelada',
  'Order $$$ received': 'Orden $$$ recibida',
  'Are you sure you received the following orders: $$$': 'Esta seguro que recibiste las siguientes órdenes: $$$',
  'Cancel order': 'Cancelar orden',
  'Are you sure you want to cancel the order $$$$?': '¿Estás seguro que deseás cancelar la orden $$$$?.',
  'Error modifying order status: $$$$': 'Error modificando estado de la orden: $$$$',
  'Returning to cancel': 'Recuperando para cancelar',
  'Change the status of orders': 'Cambiar el estado de las órdenes',
  'Are you sure you want to move the following orders ($$$$) to Waiting for Preparation state?.': '¿Está seguro de que desea mover las siguiente órdenes ($$$$) al estado Esperando preparación?.',
  Prepare: 'Preparar',
  'Order $$$ waiting for preparation': 'Orden $$$ a la espera de preparación',
  'Preparing orders, please wait': 'Preparando órdenes, por favor espere',
  'Some orders are being prepared right now, please wait': 'Algunas órdenes se estan preparando, por favor espere',
  'Are you sure you want to move the following orders ($$$$) to the $$$ state?.': '¿Está seguro de que desea mover las siguiente órdenes ($$$$) al estado $$$?.',
  'Order $$$ ready for deliver': 'Orden $$$ listo para la entrega',
  Refer: 'Remitir',
  'The dispatch item is in $$$$ state and does not belong to the status prepared': 'El despacho está en estado $$$$ y no pertenece al estado preparado',
  'Send order': 'Enviar orden',
  'The order $$$$ is already in the sent state': 'La orden $$$$ ya se encuentra en estado enviado',
  'An error occurred sending the order $$$$': 'Ocurrió un error enviando la orden $$$$',
  'You do not have permission to pick order ready for delivery. Please check with the administrator': 'No posee permiso para pickear orden listo para la entrega.  Por favor chequee con el administrador',
  'Dispatch successfully sent': 'Despacho enviado exitosamente',
  'Sales by marketplace': 'Ventas por marketplace',
  'Waiting for approval': 'Esperando aprobación',
  'Waiting to cancel': 'Esperando cancelar',
  'Returned completed': 'Devolución completada',
  'Waiting for external delivery': 'Esperando entrega externa',
  'External delivery ready': 'Listo para entrega externa',
  'Ready for preparation': 'Listo para preparación',
  'Top 10 products': 'Top 10 productos',
  'Top 10 products category': 'Top 10 familia de productos',
  'Total sales': 'Total ventas',
  'Amount sales': 'Cantidad ventas',
  'No data for the chart': 'No hay datos para la gráfica',
  'en-US': 'es-AR',
  Download: 'Descargar',
  Days_per_area: 'Dias_por_area',
  'Total of orders being evaluated': 'Total de ordenes evaluadas',
  WARNING: 'AVISO',
  'Orders are being loaded for evaluation and may take some time, the number displayed during loading may differ from the number finally displayed in the preview':
    'Se estan cargando las ordenes para ser evaluadas y puede tomar un tiempo, el número mostrado durante la carga puede diferir al número finalmente mostrado en la vista previa',
  "Orders are being processed to evaluate whether they meet the requeriments. Please don't close this window while the process is loading.":
    'Las ordenes estan siendo procesadas para evaluar si cumplen con los requisitos. Por favor no cierre esta ventana mientras carga el proceso',
  'Historic by Marketplace': 'Histórico por Marketplace',
  'Last 30 days': 'Últimos 30 días',
  'Message configuration': 'Configuración de mensajes',
  'Order Document Type': 'Tipo de documento de orden',
  'Message Configuration ID': 'ID configuracion de mensaje',
  configuration: 'configuracion',
  'Edit Message Configuration': 'Editar Configuración de Mensaje',
  'Create Message Configuration': 'Crear Configuración de Mensaje',
  'Canal Off line': 'Canal Fuera de línea',
  'Edit Order': 'Editar Orden',
  'Buyer information': 'Información del comprador',
  'Buyer Address': 'Dirección del comprador',
  'Shipment address': 'Dirección de envío',
  Street: 'Calle',
  'Street Number': 'Número de Calle',
  'Extra Address': 'Dirección Extra',
  'Reset values': 'Resetear valores',
  'Copy buyer Address': 'Copiar dirección del comprador',
  Receive: 'Recibir',
  'Canal Off Line': 'Canal fuera de línea',
  'Time to run': 'Tiempo para ejecutar',
  'To upload': 'Por subir',
  'Process for change order status': 'Proceso para el cambio estado de la orden',
  'Import process': 'Proceso de importación',
  'The value is not a valid number.': 'El valor no es un número válido.',
  'The value must be only letters and spaces.': 'El valor debe ser solo letras y espacios.',
  'The value do not match the city zip codes': 'El valor no coincide con los codigos postales de la ciudad',
  'There are some errors on the fields, please fix them': 'Hay algunos errores en los campos, por favor corríjalos',
  'The value cant have special caracters': 'El valor no puede tener caracteres especiales',
  'The following processes prevent the import from being processed. Please, notify the administrator': 'Los siguientes procesos impiden que se procese  la importación. Por favor, Notifique al administrador',
  'Error executing process. Please, notify the administrator': 'Error al ejecutar el proceso. Por favor, notifique al administrador',
  Channels: 'Canales',
  Depot: 'Almacén',
  'Order $$$ delivered': 'Orden $$$ entregada',
  Deliver: 'Entregar',
  'Confirm delivery of selected orders': 'Confirmar entrega de las órdenes seleccionadas',
  'The selected orders will change the status to "Returned". Do you want to continue?': 'Las órdenes seleccionadas cambiarán el estado a "Returned". ¿Deseás continuar?',
  'process with problems': 'proceso con problemas',
  'processes with problems': 'procesos con problemas',
  'orders with problems': 'ordenes con problemas',
  'order with problems': 'orden con problemas',
  'Delete Message Configuration': 'Eliminar Mensaje de Configuración',
  'Are you sure of perform this action?': 'Estás seguro de realizar esta acción?',
  Sending: 'Enviar',
  Failed: 'Fallido',
  Queued: 'Cola',
  Reason: 'Razón',
  Title: 'Título',
  Subject: 'Asunto',
  'Source ID': 'Id de origen',
  'Message ID': 'Id de mensaje',
  'Messages sent': 'Mensajes enviados',
  Messages: 'Mensajes',
  'Message $$$ change to forward successfully': 'Mensaje $$$ cambio a reenviar exitosamente',
  'Error changing to forward message $$$': 'Error cambiando a reenviar el mensaje $$$',
  Configuration: 'Configuración',
  'Create Message Template': 'Crear Template de Mensaje',
  'Edit Message Template': 'Editar Tempate de Mensaje',
  'Message Templates': 'Templates de Mensajes',
  'Receipt of order $$$ successfully saved': 'Recibo de la orden $$$ guardada exitosamente',
  'Error saving receipt for order $$$': 'Error guardando el recibo de la orden $$$',
  'Attach receipts': 'Adjuntar recibos',
  'Messages list': 'Listado de mensajes',
  'Comments list': 'Listado de comentarios',
  'Import Account': 'Cuenta de importación',
  'Services manager': 'Gerente de servicios',
  Discounts: 'Descuentos',
  'Shipping cost': 'Costo de envíos',
  'on the road': 'en el camino',
  'in custody': 'bajo custodia',
  'entry into the operating circuit': 'entrada en el circuito de funcionamiento',
  'Are you sure you want to cancel the order $$$$ for fraud?': '¿Estás seguro que deseás cancelar la orden $$$$ por fraude?.',
  annulled: 'anulado',
  damaged: 'dañado',
  redirected: 'redirigido',
  rescued: 'rescatado',
  'withdrawal made': 'retirada realizada',
  visit: 'visitar',
  'sent in the process of coordinating': 'enviado en el proceso de coordinación',
  'surplus information': 'información excedente',
  'pending rescue': 'pendiente de rescate',
  surrendered: 'entregado',
  'pending return': 'pendiente de devolución',
  'pending entry': 'entrada pendiente',
  'at destination branch': 'en la sucursal de destino',
  surrender: 'rendirse',
  pending: 'pendiente',
  'The status was changed to Delivered. Do you want to upload receipts?': 'El estado se cambió a Entregado. ¿Desea cargar recibos?',
  'Receipt of the order': 'Recibo de la orden',
  'The total surpased the amount stock': 'El total sobrepasa la cantidad de stock',
  'Products Name': 'Nombre del Producto',
  'Edit Stock': 'Editar stock',
  'Base Stock': 'Stock Base',
  'Minimum Stock': 'Stock Mínimo',
  'Security Stock Confirmation': 'Confirmación del Stock de Seguridad',
  'Security Stock': 'Stock de Seguridad',
  'Product Description': 'Descripción del Producto',
  'Product Name': 'Nombre del Producto',
  'Recipient update': 'Actualización de destinatarios ',
  'Recipient of message $$$ successfully updated': 'Destinatario del mensaje $$$ actualizado exitosamente',
  Resend: 'Reenviar',
  'Edit Recipient': 'Modificar Destinatario',
  'Delete Message': 'Eliminar mensaje',
  'You are about to delete the message $$$$, do you want to continue?': 'Está a punto de eliminar el mensaje $$$$, ¿desea continuar?',
  'Message $$$ successfully deleted': 'Mensaje $$$ eliminado correctamente',
  'Failed to delete message $$$': 'No se pudo eliminar el mensaje $$$',
  Refresh: 'Refrescar',
  Seconds: 'Segundos',
  'No document type': 'Sin tipo de documento',
  'Message configuration deleted': 'Configuración de mensajes eliminada',
  'Message configuration $$$$ saved': 'Configuración de mensajes $$$$ guardada',
  'Successfully created message configuration': 'Configuración de mensaje creada exitosamente',
  'Updated At': 'Fecha de modificación',
  'Stock Movements': 'Movimientos de Stock',
  'Stock Administrator': 'Administrador de Stock',
  'Agent coordinator': 'Coordinador de agentes',
  'The email is invalid': 'El email es invalido',
  'The password must be at least 8 characters': 'La contraseña debe tener al menos 8 caracteres',
  'The password must have at least one upper case character': 'La contraseña debe tener al menos una mayúscula',
  'The password must have at least one lower case character': 'La contraseña debe tener al menos una minuscula',
  'The password must have at least one number character': 'La contraseña debe tener al menos un número',
  'The password must have at least special character': 'La contraseña debe tener al menos un caracter especial',
  'Sales quantity': 'Cantidad de ventas',
  Companies: 'Compañías',
  Canals: 'Canales',
  'Source type': 'Tipo de origen',
  Bad: 'Bajo',
  'Medium ': 'Mediano',
  'Almost ready': 'Casi listo',
  Excellent: 'Excelente',
  'Minimum 8 characters': 'Minimo 8 Caracteres',
  'One upper case': 'Una letra mayuscula',
  'One lower case': 'Una letra minuscula',
  'One number': 'Un numero',
  'One special character': 'Un caracter especial',
  'The email have an invalid domain': 'El email tiene un dominio invalido',
  'Number of sales by marketplace': 'Cantidad de ventas por marketplace',
  'Number of sales by company': 'Cantidad de ventas por compañía',
  'Number of sales by source type': 'Cantidad de ventas por tipo de origen',
  'All companies': 'Todas las compañías',
  'All source types': 'Todos los tipos de origen',
  'All marketplace': 'Todos los marketplaces',
  'All states': 'Todos los estados',
  'All categories': 'Todos las categorías',
  'This email is already associated with another associated id': 'Este correo electrónico ya está asociado con otro id asociado',
  'Associated id': 'Id asociado',
  'Associated email': 'Email asociado',
  'This user name is already created': 'Este nombre de usuario ya se encuentra creado',
  'Account saved': 'Cuenta guardada',
  'Returned compled': 'Retorno completado',
  'Waiting for return': 'Esperando por retorno',
  'Alert settings': 'Configuración de alerta',
  'Message alert settings': 'Configuración de alertas de mensajes',
  'Edit Message Alert settings': 'Editar configuración de alertas de mensaje',
  'Delete Message Alerts Settings': 'Configuración de eliminar alertas de mensaje',
  'Message deleted alert settings': 'Configuración de alertas de mensajes eliminados',
  'Edit Message Alert Settings': 'Editar configuración de alertas de mensajes',
  'Message Alert Settings ID': 'Id. de configuración de alertas de mensajes',
  'Create Message Alert Settings': 'Crear configuración de alertas de mensaje',
  Alert: 'Alerta',
  Template: 'Plantilla',
  'No availability to select': 'No hay disponibilidad para seleccionar',
  'No alerts available': 'No hay alertas disponibles',
  'see more': 'ver mas',
  'Total amount of sales by company': 'Monto total de ventas por compañía',
  'Total amount of sales by source type': 'Monto total de ventas por tipo de origen',
  'Apply filters': 'Aplicar filtros',
  'Delivery Date': 'Fecha de Entrega',
  'Send to': 'Enviar a',
  'Send to client': 'Enviar al cliente',
  'Edit cc': 'Editar cc',
  'CC update': 'Actualización de CC',
  'Error updating email to': 'Error al actualizar el correo electrónico',
  'The email for the saved alert $$$$': 'El email para de la alerta $$$$ guardado',
  'Edit email to': 'Editar correo electrónico para',
  'Sent data': 'Datos enviados',
  'No data to send': 'No hay datos para enviar',
  Hour: 'Hora',
  'Status sent': 'Estado enviado',
  'View name': 'Nombre de la vista',
  'Updated $$$$ alert time successfully': 'Actualizada la hora de la alerta $$$$ exitosamente',
  'Time system': 'Sistema horario',
  'Alert ID': 'ID de alerta',
  'Edit hour': 'Editar hora',
  Alerts: 'Alertas',
  'You cannot send a dispatch with orders in another state that is not ready for delivery': 'No puede enviar un despacho con ordenes en otro estado que no esté listo para la entrega',
  'Please, delete all orders that are not in the state ready for delivery, waiting for preparation or prepared':
    'Por favor, elimine todos las ordenes que no estén en el estado listos para la entrega, en espera de preparación o preparados.',
  'Sender email': 'Correo electrónico del remitente',
  Objective: 'Objetivo',
  'Goal by product': 'Objetivo por producto',
  'Goal by marketplace': 'Objetivo por marketplace',
  'Goals by products units': 'Objetivo por unidades de producto',
  'Goal by user': 'Objetivo por usuario',
  'Goal by comercial': 'Objetivo por comercial',
  'Goals by units': 'Objetivo por unidades',
  'Sales by objectives': 'Ventas por objetivos',
  'Amount goals by products units': 'Cantidad de objetivo por unidades de producto',
  'Total goals by marketplace': 'Total de objetivos por marketplace',
  'Total goals by products': 'Total de objetivos por productos',
  'Target date': 'Fecha objetivo',
  'Reporting date': 'Fecha del Informe',
  'Select a reason': 'Seleccioná un motivo',
  Cancellation: 'Cancelación',
  Refund: 'Devolución',
  Fraud: 'Fraude',
  'Retain order': 'Retener orden',
  Retain: 'Retener',
  'Are you sure you want to retain the order $$$$?': '¿Estás seguro que deseás retener la orden $$$$?',
  Liberate: 'Liberar',
  'Liberate order': 'Liberar orden',
  'Are you sure you want to liberate the order $$$$?': '¿Estás seguro que deseás liberar la orden $$$$?',
  'Order $$$$ retained': 'Orden $$$$ retenida',
  Retained: 'Retenida',
  'Cancellation for Fraud': 'Cancelado por fraude',
  Private: 'Privado',
  Public: 'Público',
  'by default': 'por defecto',
  'Filter management': 'Administración de filtros',
  'New filter': 'Nuevo filtro',
  'Create filter': 'Crear filtro',
  Preset: 'Predeterminado',
  'Go back': 'Volver',
  Comment: 'Comentario',
  Attach: 'Adjuntar',
  'Process monitor log': 'Historial de proceso',
  'Sales by goals': 'Ventas por objetivos',
  'Offline sales by goals': 'Ventas Offline por objetivos',
  Quarter: 'Trimestre',
  'All goals by marketplace': 'Todos los objetivos por mercado',
  'All goals by product': 'Todos los objetivos por producto',
  'Settings sales by goals': 'Configuración de ventas por objetivos',
  'Add goal': 'Agregar objetivo',
  'Type value': 'Valor de tipo',
  Duplicate: 'Duplicar',
  Fields: 'Campos',
  'All types value': 'Todos los tipos de valor',
  'All types goal': 'Todos los tipos de objetivo',
  '1st quarter': '1er trimestre',
  '2nd quarter': '2do trimestre',
  '3rd quarter': '3er trimestre',
  '4th quarter': '4to trimestre',
  'Goal type': 'Tipo de objetivo',
  Value: 'Valor',
  'Update goal': 'Actualizar objetivo',
  monetary: 'monetario',
  quantity: 'cantidad',
  'This product does not have an assigned price': 'Este producto no tiene un precio asignado',
  'This product does not match with the company you selected': 'Este producto no coincide con la compania seleccionada previamente',
  'Delete goal': 'Eliminar objetivo',
  'You are about to delete $$$$ goal, do you want to continue?': 'Estás a punto de eliminar el objetivo de $$$$, ¿quieres continuar?',
  'Goal $$$$ successfully modified': 'Objetivo $$$$ modificado correctamente',
  'Goal $$$$ successfully duplicated': 'Objetivo $$$$ duplicado correctamente',
  'Goal $$$$ successfully aggregated': 'Objetivo $$$$ agregado correctamente',
  'Goal deleted': 'Objetivo eliminado',
  'Loaded goals': 'Objetivos cargados',
  'Total reached of the goal': 'Total alcanzado del objetivo',
  'Percentage of goal reached': 'Porcentaje de alcance del objetivo',
  'Total goal': 'Total del objetivo',
  Monetary: 'Monetario',
  Reached: 'Alcanzado',
  'Percentage reached': 'Porcentaje alcanzado',
  'Updated order as delivered': 'Actualizar orden Entregada',
  product: 'producto',
  'Add goal period': 'Agregar período de objetivo',
  'Update goal period': 'Actualizar período de objetivo',
  'Goal period': 'Período de objetivo',
  'Goal period id': 'Id de período de objetivo',
  'Delete goal period': 'Eliminar período de objetivo',
  'You are about to remove the period $$$$ from the goal, do you want to continue?': 'Estás a punto de eliminar el período $$$$ del objetivo, ¿quieres continuar?',
  Selected: 'Seleccionado',
  'Goal field': 'Campos del objectivo',
  'Fields marketplace': 'Campos de marketplace',
  'Fields products': 'Campos de productos',
  'Loaded fields': 'Campos cargados',
  'You are about to remove the marketplace $$$$ from the goal, do you want to continue?': 'Estás a punto de eliminar el marketplace $$$$ del objectio, ¿quieres continuar?',
  'The marketplace $$$$ from the goal deleted successfully': 'El marketplace $$$$ del objetivo eliminado con éxito',
  'You are about to remove the product $$$$ from the goal, do you want to continue?': 'Estás a punto de eliminar el producto $$$$ del objectivo, ¿quieres continuar?',
  'the product $$$$ from the goal deleted': 'el producto $$$$ del objetivo eliminado',
  'You are about to remove the user $$$$ from the goal, do you want to continue?': 'Estás a punto de eliminar el usuario $$$$ del objetivo, ¿quieres continuar?',
  'The user $$$$ from the goal deleted successfully': 'El usuario $$$$ del objetivo eliminado con éxito',
  'The name already exists': 'El nombre ya existe',
  'Edit filter': 'Editar filtro',
  'GW of payments': 'GW de Pago',
  'Orders for policies': 'Ordenes para pólizas',
  'Last change': 'Ultimo cambio',
  'Created at': 'Fecha de creacion',
  'Order Documents': 'Documentos de orden',
  'Buyer Name': 'Nombre del comprador',
  'Buyer Document': 'Documento del comprador',
  'Document Date': 'Fecha del documento',
  'Orders by transport': 'Órdenes por transporte',
  'Delivery control': 'Control de entrega',
  'Settings SLA': 'Configuración de SLA',
  'Date - waiting for preparation': 'Fecha - Esperando preparación',
  'Hour - waiting for preparation': 'Hora - Esperando preparación',
  'Date - out for delivery': 'Fecha - en camino',
  'Hour - out for delivery': 'Hora - en camino',
  'Date - delivered': 'Fecha - entregado',
  'Hour - delivered': 'Hora - entregado',
  'Total hours (Operative)': 'Total de horas (Operativa)',
  'Total hours (Delivered)': 'Total de horas (Entrgado)',
  Effectiveness: 'Efectividad',
  'Effectiveness (Operative)': 'Efectividad (Operativa)',
  'Effectiveness (Delivered)': 'Efectividad (Entregado)',
  'Loaded delivery control': 'Control de entrega cargados',
  'Loaded Online': 'Online cargados',
  'Loaded Offline': 'Offline cargados',
  'Average time in hours (Operative)': 'Tiempo promedio en horas (Operativa)',
  'Average time in hours (Delivered)': 'Tiempo promedio en horas (Entregado)',
  'Add SLA': 'Agregar SLA',
  'Update SLA': 'Actualizar SLA',
  'Marketplace sla id': 'Id de marketplace sla',
  'Marketplace $$$$ successfully modified': 'Marketplace $$$$ modificado correctamente',
  'Marketplace $$$$ successfully aggregated': 'Marketplace $$$$ agregado correctamente',
  'You are about to delete $$$$ marketplace sla, do you want to continue?': 'Estás a punto de eliminar $$$$ sla del mercado, ¿quieres continuar?',
  'Delete marketplace sla': 'Eliminar el SLA de Marketplace',
  '% Quantity (Operative)': '% Cantidad (Operativa)',
  '% Quantity (Delivered)': '% Cantidad (Entregado)',
  'Does not have GW of payments': 'No tiene GW de pago',
  'Does not have Payment Method': 'No tiene Metodo de pago',
  'Administrator management': 'Administrador gestión ',
  Indicator: 'Indicador',
  'Number of total claims per account': 'Número total de reclamaciones por cuenta',
  'Number of claims affecting reputation': 'Número de reclamaciones que afectan a la reputación',
  'Total sales per account': 'Ventas totales por cuenta',
  'Sales cancelled by the buyer': 'Ventas canceladas por el comprador',
  'Sales cancelled by the seller': 'Ventas canceladas por el vendedor',
  'Sales cancelled by mercadolibre': 'Ventas canceladas por mercadolibre',
  'Order $$$ liberated': 'Order $$$ liberada',
  'Working days': 'Días laborables',
  'Average orders per day': 'Promedio de ordenes por día',
  'Number of online orders': 'Número de ordenes online',
  'Number of offline orders': 'Número de ordenes offline',
  'Non-working days': 'Días no laborables',
  'Loaded non-working days': 'Días no laborables cargados',
  'Add non-working day': 'Agregar día no laborable',
  'Update non-working day': 'Actualizar día no laborable',
  'Non-working day id': 'Id de día no laborable',
  'Non-working day $$$$ successfully modified': 'Día no laborable $$$$ modificado correctamente',
  'Non-working day $$$$ successfully aggregated': 'Día no laborable $$$$ agregado correctamente',
  'Non-working day deleted': 'Día no laborable eliminado',
  Vat: 'IVA',
  'Sales taxation': 'Impuestos sobre las ventas',
  'Total vat': 'Total IVA',
  'Total with taxation': 'Total con impuestos',
  'Total without taxation': 'Total sin impuestos',
  'In time': 'A tiempo',
  'Out of time': 'Fuera de tiempo',
  'Effectiveness offline (Operative)': 'Efectividad offline (Operativa)',
  'Effectiveness online (Operative)': 'Efectividad online (Operativa)',
  'Effectiveness offline (Delivered)': 'Efectividad offline (Entregado)',
  'Effectiveness online (Delivered)': 'Efectividad online (Entregado)',
  'Sales by Sku': 'Ventas por Sku',
  'Sales by Courier': 'Ventas por Mensajeria',
  'Sales by Orders and Items': 'Ventas por Ordenes y Productos',
  'Number of Sales by Product': 'Numero de Ventas por Producto',
  'Number of Orders by Couriers': 'Numero de Ordenes por Mensajeria',
  'Number of Orders and Products': 'Numero de Ordenes y Productos',
  'Orders and Products': 'Orenes y Productos',
  'Total items sales': 'Ventas totales por items',
  'Total orders sales': 'Ventas totales por ordenes',
  'Total orders': 'Total ordenes',
  'Error please provide an indicator': 'Error por favor insertar un indicador',
  'Search Skus': 'Buscar Skus',
  'Items and Orders': 'Items y Ordenes',
  'Shipment Type': 'Tipo de Entrega',
  'Buyer ID': 'ID del comprador',
  Method: 'Método',
  'Payment Method': 'Método de Pago',
  'Unit Price': 'Precio Unitario',
  'Edit Budget Product': 'Editar Producto del Presupuesto',
  Budget: 'Presupuesto',
  'Budget revision': 'Presupuesto en revision',
  'Are you sure you wanna go back?': 'Estás seguro que deseas volver atrás?',
  'Leaving budget form': 'Volviendo a presupuestos',
  'Store Pickup': 'Retiro en Sucursal',
  Approve: 'Aprobar',
  Reject: 'Rechazar',
  Updating: 'Actualizando',
  'User Name': 'Nombre del Usuario',
  Approving: 'Aprobando',
  'Edit budget': 'Editar presupuesto',
  'Budget Confirmation': 'Confirmación del Presupuesto',
  'Value term delivery': 'Entrega de plazo valor',
  'The budget to be approved contains prices different from the price list, do you want to continue?': 'El presupuesto que desea aprobar contiene precios diferentes a la lista de precios, ¿Desea continuar?',
  'The products $$$$ do not have enough stock in odoo': 'Los productos $$$$ no tienen stock suficiente en odoo',
  'Please select a payment method': 'Por favor seleccione un metodo de pago',
  'This product does not match with the payment method you have selected': 'No es posible agregar este producto con el metodo de pago seleccionado',
  'This payment method does not match with one of the products you have selected': 'No es posible agregar este metodo de pago porque no aplica para uno de los produtos selcionados',
  'There are some differences between odoo and ster orders. Please review them.': 'Encontramos diferencias entre odoo y ster. Por favor revisarlas.',
  'The orders $$$$ successfully saved': 'Las ordenes $$$$ guardadas con exito',
  'The order $$$$ successfully saved': 'La orden $$$$ guardada con exito',
  'Sales objective': 'Objetivo de ventas',
  'Justify Order id': 'Justificar la orden con id',
  'Justification of order id': 'Justificación de la orden con id',
  Justify: 'Justificación',
  Justified: 'Justificado',
  'Enter the justification here': 'Ingrese la justificación aqui',
  Commissions: 'Comisiones',
  Discount: 'Descuento',
  Interest: 'Intereses',
  'Bonus Charger': 'Cargo Adicional',
  'Return Charge': 'Cargo de Devolución',
  'Shipping Charge': 'Gastos de Envío',
  'Logs justification order id': 'Historil de la justificación de orden id',
  Leave: 'Salir',
  Justification: 'Justificación',
  Operation: 'Operación',
  Unjustified: 'Injustificado',
  Process: 'Procesar',
  'Sync with Odoo': 'Sincronización con Odoo',
  'Range type': 'Tipo de rango',
  Week: 'Semana',
  'Product Category': 'Categoría del Producto',
  'Sales Execution': 'Ventas Realizadas',
  Channel: 'Canal',
  Year: 'Año',
  'Weeks Information': 'Información de las Semanas',
  'Days Information': 'Información de los Días',
  'Week Number': 'Número de la Semana',
  'Objective quantity': 'Cantidad del Objetivo',
  'Sale quantity': 'Cantidad Alcanzada',
  'Objective amount': 'Monto del Objetivo',
  'Sale amount': 'Monto Alcanzado',
  Category: 'Categoría',
  'Reset the table': 'Restablecer al tabla',
  Monday: 'Lunes',
  Tuesday: 'Martes',
  Wednesday: 'Miércoles',
  Thursday: 'Jueves',
  Friday: 'Viernes',
  Days: 'Días',
  'Awaiting approval': 'Esperando aprobación',
  'Objective by billing': 'Objetivos por facturación',
  'Stock to Publish': 'Stock a Publicar',
  'Custom Stock in Publication': 'Stock Personalizado en la Publicación',
  Draft: 'Borrador',
  'Draft Revision': 'Borrador en Revisión',
  'Billing Address': 'Dirección de Facturación',
  'Shipping Address': 'Dirección de Envio',
  Carrier: 'Transportista',
  Carriers: 'Transportistas',
  'The $$$$ field is required': 'El campo $$$$ es requerido.',
  'Carrier id': 'Id del Transportista',
  'Carrier saved': 'Transportista guardado',
  'Reporting dashboard': 'Tablero de reportes',
  'Report B2B Stock': 'Reporte de stock B2B',
  Providers: 'Proveedores',
  Debtors: 'Deudores',
  'Valued general': 'Valorizado General',
  'Valued in transit (FOB)': 'Valorizado en transito (FOB)',
  'Valued by category': 'Valorizado por Categoria',
  'Valued by segment (Production/Saleable/fixed assets)': 'Valorizado por segmentacion (Produccion/Vendible/inmovilizado)',
  Financials: 'Financieros',
  'SLA (Operative)': 'SLA (Operativa)',
  'SLA (Delivered)': 'SLA (Entregado)',
  'Quantity (Operative)': 'Cantidad (Operativa)',
  'Quantity (Delivered)': 'Cantidad (Entregado)',
  'On time on track': 'A tiempo en Camino',
  'Out of time on Track': 'Fuera de tiempo en Camino',
  'On time Delivered': 'A tiempo Entregado',
  'Out of time Delivered': 'Fuera de tiempo Entregado',
  'Company Cost': 'Costos',
  'Average daily sales for the month': 'Promedio diario de ventas del mes',
  Workforce: 'Personal',
  'Structure Cost': 'Costos de Estructura',
  goal: 'Total del Objetivo',
  porcent: 'Porcentaje de Alcance del Objetivo',
  reached: 'Total Alcanzado del Objetivo',
  Transit: 'Tránsito',
  transit: 'Valorizado en Tránsito (FOB)',
  'By Segment': 'Por Segmento',
  saleable: 'Vendible',
  'fixed assets': 'Inmovilizado',
  'Amount Month': 'Monto Mensual',
  'Historic Amount': 'Monto Historico',
  Objectives: 'Objetivos',
  'Offline Sales by Objectives': 'Ventas Offline por Objetivos',
  'Top 20 Products': 'Top 20 Productos',
  'Top 20 Customers': 'Top 20 Clientes',
  overdue: 'Vencido',
  current: 'Corriente',
  Currency: 'Moneda',
  'Paid in Advance': 'Pago por adelantado',
  'Delivery Days': 'Días de entrega',
  'Deferred Payment Days': 'Días de pago diferidos',
  Balances: 'Saldos',
  'securities check balance': 'Saldo de Cheques de Valores',
  'investment balance': 'Saldo de Inversiones',
  'own check balance': 'Saldo de Cheques Propios',
  'lender balance': 'Saldo de Prestamos',
  'bank balance': 'Saldo de Bancos',
  'Overall Valued': 'Valorizado General',
  'Valued in Transit (FOB)': 'Valorizado en Tránsito (FOB)',
  'Valued by Category': 'Valorizado por Categoría',
  'Valued by segmentation': 'Valorizado por segmentación',
  'Reporting Date': 'Fecha de Reporte',
  'Total sales of the month': 'Total ventas del mes',
  'Total amount of dispatch': 'Monto total del despacho',
  Margins: 'Margenes',
  'Net Margin': 'Margen Neto',
  'Gross Margin': 'Margen Bruto',
  'Sales of the day': 'Ventas del día',
  'Bar code': 'Código de barra',
  'QR Code': 'Código QR',
  'Odoo Synchronization': 'Sincronización con Odoo',
  'Stock below Minimum': 'Stock por debajo del Mínimo',
  'User assigned': 'Usuario Asignado',
  'Pick Control': 'Control de Pickeo',
  'Clients with turns': 'Clientes con turnos',
  'Create document': 'Crear documento',
  'Update document': 'Actualizar documento',
  'Delete document': 'Eliminar documento',
  'Do you want to delete the document $$$$?': '¿Quieres eliminar el documento $$$$?',
  'Document updated': 'Documento actualizado',
  'Document created': 'Documento creado',
  'Document ID': 'ID del docuemnto',
  'Serial number information': 'Información del número de serie',
  'Go to Collect': 'Ir a la collecta',
  'Dispatch ID': 'ID del despacho',
  'Collect ID': 'ID de la Colecta',
  'Cart ID': 'ID del carro',
  'Serial Number': 'Número de serie',
  'Invalid Serial Number': 'Número de serie no válido',
  'Validate cart': 'Validar carro',
  'Validate Serial Number': 'Validar N° de serie',
  'Quantity deferred payment': 'Cantidad de pago aplazado',
  'Quantity delivery days': 'Fecha de Entrega de Mercadería',
  'Expiration date': 'Validez de presupuesto',
  'Search Serial Number': 'Buscar Número de Serie',
  'Search Cart': 'Buscar Carrito',
  'Scan the code of the dispatch table': 'Escaneé el código de la mesa de despachos',
  'Go to the dispatch table and scan the code': 'Diríjase a la mesa de despachos y escaneé el código',
  Code: 'Código',
  'The scanned code is not correct': 'El código escaneado no es correcto',
  'The aggregated order does not have the status prepared': 'La orden agregada no tiene el estado preparado',
  'Transport packages': 'Transportar paquetes',
  'There was an error loading the documents': 'Hubo un error al cargar los documentos',
  canceled: 'cancelado',
  'not delivered': 'No entregado',
  'Last update since': 'Última actualización desde',
  'Last update until': 'Última actualización hasta',
  collected: 'Colectado',
  'waiting for external delivery': 'Esperando por entrega externa',
  'waiting to cancel': 'Esperando cancelar',
  'returned completed': 'Devolución completada',
  'returned compled': 'Devolución completada',
  all: 'Todo',
  operative: 'Operativa',
  'Quantity Deferred Payment': 'Entrega plazo valor',
  'Quantity Delivery Days': 'Entrega de mercadería',
  'ML Periods': 'Periodos ML',
  'Does not have quantity': 'No tiene data',
  Coupon: 'Cupón',
  'Coupon Id': 'ID Cupón',
  'Discount Id': 'ID Descuento',
  'Is the budget approved by the client?': '¿El presupuesto se encuentra confirmado por el cliente?',
  'Confirm Budget': 'Confirmar Presupuesto',
  'Budget to be confirmed': 'Presupuesto a confirmar',
  'Accepted Budget': 'Presupuesto aceptado',
  'Are you sure to confirm the budget?': 'Esta seguro de aceptar el presupueto?',
  'Order has been confirmed successfully': 'Order ha sido confirmada exitosamente',
  'Add Payment Method': 'Añadir metodo de pago',
  Copy: 'Copiar',
  'Are you sure you want to delete $$$$ group? It will also delete all the features linked to it': 'Estas seguro que deseas eliminar el grupo $$$$? También eliminará las características relacionadas.',
  'Are you sure you want to delete $$$$ feature': 'Estas seguro que deseas eliminar la característica $$$$',
  'Features Bulk Load': 'Carga masiva de características',
  'Bulk Load': 'Carga Masiva',
  'Copy features by SKU': 'Copiar características por SKU',
  'Add Group': 'Añadir grupo',
  'Add Feature': 'Añadir caracteristica',
  'Feature Group': 'Grupo de caracteristica',
  'Feature Table': 'Tabla de caracteristicas',
  'Group ID': 'ID Grupo',
  Searchable: 'Buscable',
  Features: 'Caracteristicas',
  'Product Information': 'Información del producto',
  Images: 'Imagenes',
  'Deliver Orders': 'Entregar Ordenes',
  'Sale Type': 'Tipo de Venta',
  'Advance Sale': 'Venta Anticipada',
  'Advance sale': 'Venta anticipada',
  'Is Advance Sale?': '¿Es una venta anticipada?',
  'Is it paid in advance': '¿Es pago anticipado',
  'Budget Preview': 'Vista Previa del presupuesto',
  'Budget Detail': 'Detalle del persupuesto',
  Preview: 'Vista Previa',
  'Features Preview': 'Vista previa de características',
  'Technical Specifications': 'Especificaciones técnicas',
  'Reload Prices': 'Recalcular Precios',
  'Quantity od each product should be more or equal than Payments Methods': 'La cantidad de cada producto debe ser superior o igual a la cantidad de métodos de pago',
  'Save Price Change': 'Guardar Cambio de precio',
  'Do you want to upload receipts?': '¿Quieres cargar recibos?',
  'agreed delivery date': 'Fecha de entrega acordada',
  Purchases: 'Compras',
  Stock: 'Inventario',
  'Quicksight reports': 'Reportes quicksight',
  'Ster processes': 'Procesos de Ster',
  'There was an error getting the product': 'Hubo un error al obtener el producto',
  'Shipping ways': 'Formas de envío',
  'percentage explanation': 'El porcentaje mostrado esta basado en el margen de ganacia del producto',
  'Missing Tier': 'Tier faltante',
  'Select the shipping method': 'Seleccione la forma de envío',
  'Enter billing address': 'Ingrese  dirección de facturación',
  'Select a client': 'Seleccione un cliente',
  'There are equal payment methods': 'Existen metodos de pagos iguales',
  'Payment Methods': 'Metodos de pago',
  'There cannot be products with quantity 0': 'No pueden haber productos con cantidad 0',
  'Something is Wrong With Order Creation': 'Algo anda mal con la creación de órdenes',
  'Could not get price list': 'No se pudo obtener la lista de precios',
  '% discount': '% de descuento',
  'The selected client does not have a configured tier': 'El cliente seleccionado no tiene un tier configurado',
  'Month & year': 'Mes y año',
  'The marketplaces ($$$$) are already registered for the selected period': 'Los marketplaces ($$$$) ya están registrados en el periodo seleccionado',
  'Select the agreed delivery date': 'Seleccione la fecha de entrega acordada',
  'Daily sales required': 'Venta diaria necesaria',
  Select: 'Selecciona',
  'or drag the files here': 'o arrastrá los archivos aqui',
  Removing: 'Eliminando',
  'We are removing the information related to the dispatch with ID:': 'Nos encontramos eliminando la información relacionada al despacho con ID:',
  'With turn?': '¿Con turno?',
  'Billing Table': 'Tabla de Facturación',
  'Units Table': 'Tabla de Unidades',
  'Paid in advance': 'Pago anticipado',
  January: 'Enero',
  February: 'Febrero',
  March: 'Marzo',
  April: 'Abril',
  May: 'Mayo',
  June: 'Junio',
  July: 'Julio',
  August: 'Agosto',
  September: 'Septiembre',
  October: 'Octubre',
  November: 'Noviembre',
  December: 'Diciembre',
  Goal: 'Objetivo',
  Billing: 'Facturación',
  Units: 'Unidades',
  '% Complied': '% Cumplido',
  Difference: 'Diferencia',
  Projection: 'Proyección',
  '% Projection Complied': '% Cumplido de Proyección',
  Months: 'Meses',
  msg: 'Mensaje',
  'Orders updated to assinged': 'Ordenes con turno actualizadas a asignado',
  'Cannot update the orders': 'No se pudo actualizar las ordenes',
  'Pending of turn': 'Pendiente de turno',
  'We are updating the orders': 'Nos encontramos actualizando las ordenes',
  Turn: 'Turno',
  Cancellations: 'Cancelaciones',
  Penalties: 'Penalidades',
  Refunds: 'Reintegros',
  'Market expenses': 'Gastos del Market',
  Receipts: 'Recibos',
  'Attach receipt': 'Adjuntar recibo',
  Brand: 'Marca',
  'All Categories': 'Todas las categorías',
  'All Brands': 'Todas las marcas',
  'Update state': 'Actualizar estado',
  'The “Base Stock” cannot be equal to or less than the “Minimum Stock”.': 'El "Stock Base" no puede ser igual o inferior al "Stock Mínimo".',
  'The “Minimum Stock” cannot be equal to or higher than the “Base Stock”.': 'El "Stock Mínimo" no puede ser igual o superior al "Stock Base".',
  'Product SKU': 'SKU Producto',
  'Current Stock': 'Stock Actual',
  'Valued Stock': 'Stock Valorizado',
  'Units Sold': 'Unidades Vendidas',
  'Stock Days': 'Días de Stock',
  'Units Sold Stock Days': 'Unidades Vendidas en Días Stock',
  'Total sold': 'Total  vendido',
  "The selected stock api's are now being processed in the background": "Las api's seleccionadas estan siendo procesadas en segundo plano",
  'There was an error while trying to sync the selected processes': 'Hubo un error al intentar sincronizar las los procesos seleccionados',
  Synchronize: 'Sincronizar',
  Sync: 'Sincronizar',
  'Last global excecution': 'Ultima ejecución global',
  'Last manual excecution': 'Ultima ejecución manual',
  Active: 'Activo',
  'Stock process sync': 'Sincronización de procesos de stock',
  "You're about to sync the following API's with it's corresponding markets": "Estas a punto de sincronizar las siguientes API's con sus marketplaces",
  'This may take some time': 'Esto puede llevar unos minutos',
  Chargeback: 'Contracargo',
  unregistered: 'no registrado',
  'Chargeback Report': 'Reporte de Contracargos',
  'Positive Chargeback Amount': 'Monto de contracargo positivo',
  'Positive Chargeback Percentage': 'Porcentaje de contracargo positivo',
  'Total Positive Chargeback': 'Total de contracargos positivos',
  'Money earned': 'Dinero recaudado',
  Index: 'Indice',
  'Negative Chargeback Amount': 'Monto de contracargo negativo',
  'Total Negative Chargeback': 'Total de contracargos negativos',
  'Total Orders': 'Ordenes Totales',
  'Chargeback by City': 'Contracargo por Ciudad',
  'Chargeback by Address': 'Contracargo por Dirección',
  'Chargeback by Product': 'Contracargo por Producto',
  'Settings Chargeback Marketplaces': 'Configuración de Marketplaces de Contracargo',
  'Total amount': 'Monto total',
  View: 'Vista',
  'Stock days report': 'Reporte días de stock',
  'Chargeback report': 'Reporte de contracargos'
};
