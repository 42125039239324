import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import { Grid, Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { ScreenAligned } from '../../../../components/ScreenAligned/ScreenAligned';
import { AnyObject, LibbyObject } from '../../../../types';
import { useDispatchesDetail, usePrepareOrder } from '../../hook';
import { Dispatch_item, Dispatch } from '../../../../interfaces/business';
import { OrderItems } from './components/OrderItems';
import { DispatchDetailData } from './components/DispatchDetailData';
import { useTranslation } from '../../../../services/translation';
import { LoadingTable } from '../../../../components/LoadingTable';
import { TitleBreadcrumbs } from '../../../../interfaces';
import { useBreadcrumbs } from '../../../../hooks';
import { ButtonComponent } from '../../../../components/Button';
import { ButtonDetailsType } from '../../../../types/Button';
import { DISPATCH_ITEM_STATE, DISPATCH_STATE, ORDER_STATE } from '../../../../const';
import { useCreateUpdateCollectModal } from '../../../Collection/hook/useCreateUpdateCollectModal';
import { collectItemType } from '../../../Collection/types/Collect';
import { filterDispatchItemsByState } from '../../utils/filter';
import { TokenManager } from 'platform/libby/TokenManager';
import { API_URL } from 'config';
import { ASSIGN_TURN } from 'const';
import { DialogLoading } from 'components/DialogLoading';

export const useStylesButtonDispatch = makeStyles((theme: AnyObject) => ({
  disabled: {
    '@media (max-width: 550px)': {
      display: 'none'
    }
  },
  buttonLargeWidth: {
    fontFamily: '"Oswald"',
    padding: '5px 22px',
    minWidth: 100
  },
  buttonSendDispatch: {
    fontFamily: '"Oswald"',
    padding: '5px 22px',
    minWidth: 100,
    background: '#C4C2C4',
    color: 'white'
  },
  marginButton: {
    margin: '7px 7px'
  },
  msg: {
    textAlign: 'center'
  },
  modal: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    top: '5%',
    left: '5%',
    right: '5%',
    width: '90%',
    height: 'auto',
    outline: 'none'
  },
  btnWidth: {
    '@media (max-width: 550px)': {
      width: '45%'
    }
  }
}));

type DispatchDetailProps = {
  onEditDispatch: (k?: Dispatch) => void;
  path: string;
  libby: LibbyObject;
  title: TitleBreadcrumbs[];
  reFetch: () => void;
  handleUpdateDispatchList: (dispatch: Dispatch, id: string) => void;
  resetFilter: () => void;
};

const availableStates = ['1', '6', '4'];

export const DispatchDetailRaw = ({ path, onEditDispatch, libby, title, reFetch, handleUpdateDispatchList, resetFilter }: DispatchDetailProps) => {
  const { dispatch_id } = useParams<{ dispatch_id: string }>();
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const classes = useStylesButtonDispatch();
  const history = useHistory();
  const { state } = useLocation<{ goBack: TitleBreadcrumbs[] }>();
  const { enqueueSnackbar } = useSnackbar();
  const [isOrdersAssigned, setIsOrdersAssigned] = useState(false);

  const { rows, checked, working, newData, columns, readOnlyData, msg, addItemInOrderItems, toFinishDispatch, checkIfDispatchIsReady, setNewData, printDispatchNote, searchPrintRefer, setOrderItems, updateDataDetail, orderItems } =
    useDispatchesDetail({
      libby,
      id: dispatch_id,
      reFetch,
      handleUpdateDispatchList,
      resetFilter,
      showModalCancelledShow: true
    });

  const {
    onPrepareOrder,
    handleOpenInvoiceList,
    handleOpenLabelsModalProducts,
    onCompletePackage,
    showModalTagManagerView,
    setShowModalTagManagerView,
    tagView,
    setTagView,
    loading: gettingDocuments,
    orderStateState,
    updateOrderState
  } = usePrepareOrder(libby, {
    checked,
    dispatchData: newData,
    checkIfDispatchIsReady,
    setNewData,
    setOrderItems
  });

  const { handleOnOpenCollectModal } = useCreateUpdateCollectModal({
    libby,
    dispatchId: dispatch_id
  });

  const checkDispatchValidation = useCallback((): void => {
    const availableStateData = availableStates.includes(newData?.dispatch_state?.dispatch_state_id);
    if (history.location?.pathname.includes('/dispatches/detail/') && !availableStateData) {
      history.push(`/dispatches/inquiry/detail/${newData.dispatch_id}`);
    } else if (history.location?.pathname.includes('/dispatches/inquiry/detail/') && availableStateData) {
      history.push(`/dispatches/detail/${newData.dispatch_id}`);
    }
    setLoading(false);
  }, [history, setLoading, newData]);

  useEffect(() => {
    if (loading && newData?.dispatch_state?.dispatch_state_id.length) {
      checkDispatchValidation();
    }
  }, [newData, loading, checkDispatchValidation]);

  useEffect(() => {
    const fetch = async () => {
      const resp = await axios.get(`${API_URL}/${ASSIGN_TURN}?dispatchId=${dispatch_id}`, {
        headers: {
          'x-chino-token': await TokenManager.create().retrieve()
        }
      });
      setIsOrdersAssigned(resp.data?.allowSend);
    };
    fetch();
  }, [dispatch_id]);

  const titleShow = useBreadcrumbs(`${newData?.name || ''}`, state?.goBack?.length ? state.goBack : title);

  const addOrdersStep = useCallback(() => {
    history.push({
      pathname: `${path}/add-orders/${newData.dispatch_id}/${newData?.courier_service?.courier_service_id}`,
      state: {
        goBack: titleShow
      }
    });
  }, [history, newData, path, titleShow]);

  const items: collectItemType[] = useMemo(() => {
    const dispatchItemSelect = checked?.all?.filter(
      (dispatchItem: any) => dispatchItem.dispatch_item_state.dispatch_item_state_id === DISPATCH_ITEM_STATE.ASSIGNED && dispatchItem?.order?.state?.order_state_id === ORDER_STATE.WAITING_FOR_PREPARATION.toString()
    );
    return dispatchItemSelect.map((value: any) => ({
      dispatch_item: {
        dispatch_item_id: value.dispatch_item_id.toString()
      }
    }));
  }, [checked]);

  const ordersChecked: string[] = useMemo(() => {
    return checked?.all?.map((itemCheck: any) => itemCheck.order.order_id);
  }, [checked]);

  const buttonDetails = useMemo((): ButtonDetailsType[] => {
    const stateDispatch = newData?.dispatch_state?.dispatch_state_id;

    if (stateDispatch === DISPATCH_STATE.SENT) {
      // HIDE Manifesto
      return [
        {
          id: 'printDispatchNote',
          onClick: printDispatchNote,
          title: 'Print manifest',
          className: classes.buttonLargeWidth,
          type: 'submit',
          variant: 'contained',
          loading: searchPrintRefer
        }
      ];
    }

    if (stateDispatch === DISPATCH_STATE.CANCELLED) {
      return [];
    }
    const buttons: ButtonDetailsType[] = [
      {
        id: 'dispatchDetailAddOrder',
        onClick: () => {
          history.push({
            pathname: `${history.location?.pathname}/collects`,
            state: {
              goBack: titleShow
            }
          });
        },
        title: 'Collects',
        className: classes.buttonLargeWidth,
        disabled: stateDispatch === DISPATCH_STATE.CANCELLED
      },
      {
        id: 'dispatchDetailAddOrder',
        onClick: () => {
          const titleHandle = [...titleShow];

          titleHandle.push({
            name: 'Collects',
            href: `/dispatches/detail/${dispatch_id}/collects`
          });
          handleOnOpenCollectModal(
            {
              dispatch: { dispatch_id },
              items
            },
            true,
            titleHandle
          );
        },
        title: 'Add collect',
        className: classes.buttonLargeWidth,
        disabled: !items?.length
      },
      {
        id: 'dispatchDetailAddOrder',
        onClick: addOrdersStep,
        title: 'Add Order',
        className: classes.buttonLargeWidth
      }
    ];

    if (newData?.state_turn === +DISPATCH_ITEM_STATE.PREPARING_PACKAGE) {
      buttons.push({
        id: 'dispatchAssignedTurn',
        onClick: async () => {
          try {
            await axios.put(
              `${API_URL}/${ASSIGN_TURN}`,
              { orderIds: ordersChecked },
              {
                headers: {
                  'x-chino-token': await TokenManager.create().retrieve()
                }
              }
            );
            enqueueSnackbar(t('Orders updated to assinged'), { variant: 'success' });
          } catch (error) {
            enqueueSnackbar(t('Cannot update the orders'), {
              variant: 'error'
            });
          }
        },
        title: 'Assigned turn',
        className: classes.buttonLargeWidth,
        disabled: !checked?.all?.length
      });
    }
    if (!newData.items) return buttons;

    const itemsToCheck = checked.all.map((item: any) => {
      const updatedItem = newData.items.find(({ dispatch_item_id }: Dispatch_item) => dispatch_item_id === item.dispatch_item_id);
      return updatedItem || item;
    });
    const anyDispatchItemToPrepare = filterDispatchItemsByState({
      orderState: ORDER_STATE.WAITING_FOR_PREPARATION.toString(),
      dispatchItemState: DISPATCH_ITEM_STATE.PREPARING_PACKAGE,
      dispatchItems: itemsToCheck
    });
    const anyDispatchItemToComplete = filterDispatchItemsByState({
      orderState: ORDER_STATE.READY_FOR_DELIVERY.toString(),
      dispatchItemState: DISPATCH_ITEM_STATE.PREPARING_PACKAGE,
      dispatchItems: itemsToCheck
    });
    const anyDispatchItemAssignedToComplete = filterDispatchItemsByState({
      orderState: ORDER_STATE.READY_FOR_DELIVERY.toString(),
      dispatchItemState: DISPATCH_ITEM_STATE.ASSIGNED,
      dispatchItems: itemsToCheck
    });
    const dispatchItemsSent = filterDispatchItemsByState({
      dispatchItemState: DISPATCH_ITEM_STATE.SENT,
      dispatchItems: orderItems
    });

    if (anyDispatchItemToPrepare.length) {
      buttons.push({
        id: 'dispatchPrepareOrder',
        onClick: onPrepareOrder,
        title: 'Refer',
        className: classes.buttonLargeWidth
      });
    }
    if (anyDispatchItemToComplete.length || anyDispatchItemAssignedToComplete.length) {
      buttons.push(
        {
          id: 'labels',
          onClick: handleOpenLabelsModalProducts,
          title: 'Labels',
          className: classes.buttonLargeWidth
        },
        {
          id: 'invoicestrakers',
          onClick: handleOpenInvoiceList,
          title: 'Invoice and Tracker',
          className: classes.buttonLargeWidth
        },
        {
          id: 'finish',
          onClick: onCompletePackage,
          title: 'Finish',
          className: classes.buttonLargeWidth
        }
      );
    }

    if (
      dispatchItemsSent.length &&
      dispatchItemsSent.length === orderItems.length &&
      newData.dispatch_state.dispatch_state_id !== DISPATCH_STATE.CANCELLED &&
      newData.dispatch_state.dispatch_state_id !== DISPATCH_STATE.SENT &&
      isOrdersAssigned
    ) {
      buttons.push({
        id: 'dispatchDetailSend',
        onClick: toFinishDispatch,
        title: 'Send',
        className: classes.buttonLargeWidth,
        type: 'submit',
        variant: 'contained'
      });
    }

    if (checked.all.length)
      buttons.push({
        id: 'updateStateButton',
        onClick: updateOrderState,
        title: 'Update state',
        className: classes.buttonLargeWidth,
        disabled: stateDispatch === DISPATCH_STATE.CANCELLED
      });

    return buttons;
  }, [
    addOrdersStep,
    classes,
    history,
    dispatch_id,
    titleShow,
    items,
    handleOnOpenCollectModal,
    handleOpenInvoiceList,
    ordersChecked,
    enqueueSnackbar,
    onPrepareOrder,
    handleOpenLabelsModalProducts,
    onCompletePackage,
    checked,
    newData,
    toFinishDispatch,
    printDispatchNote,
    searchPrintRefer,
    orderItems,
    isOrdersAssigned,
    t,
    updateOrderState
  ]);

  return (
    <ScreenAligned
      title={titleShow}
      additionalTitle={
        <>
          {!working && (
            <Grid container>
              {buttonDetails.map((dataButton) => (
                <ButtonComponent
                  key={dataButton.id}
                  title={dataButton.title}
                  className={dataButton.className}
                  variant={dataButton.variant}
                  color={dataButton.color}
                  onClick={dataButton.onClick}
                  type={dataButton.type}
                  disabled={dataButton.disabled}
                  loading={dataButton.loading}
                />
              ))}
            </Grid>
          )}
        </>
      }
    >
      <Box my={3}>
        <DispatchDetailData working={working || gettingDocuments} data={newData} readOnlyData={readOnlyData} onEditDispatch={onEditDispatch} updatedDataDetail={updateDataDetail} />
      </Box>

      {!working && (
        <>
          {libby.working && (
            <>
              <LoadingTable />
              <h3 className={classes.msg}>{t(msg)}</h3>
            </>
          )}
          <OrderItems rows={rows} columns={columns} working={libby.working} checked={checked} id={dispatch_id} addItemInOrderItems={!readOnlyData ? addItemInOrderItems : undefined} />
        </>
      )}
      <Modal
        onClose={() => {
          setShowModalTagManagerView(false);
          setTagView(<></>);
        }}
        open={showModalTagManagerView}
      >
        <div className={classes.modal}>{tagView}</div>
      </Modal>
      {orderStateState.loading && <DialogLoading title={t('Updating')} content={`${t('We are updating the orders')}: ${orderStateState.count} ${t('of')} ${orderStateState.totalCount}`} />}
    </ScreenAligned>
  );
};

export const DispatchDetail = DatabaseConnector(DispatchDetailRaw)(
  'ster_dispatch_details',
  'ster_dispatch_collect',
  'ster_dispatch_collect_details',
  'ster_dispatch_item',
  'ster_order',
  'ster_order_document',
  'ster_order_so',
  'ster_order_table',
  'ster_dispatch',
  'ster_dispatch_update',
  'ster_dispatch_details_update',
  'ster_collect_item_product',
  'ster_dispatch_item_send',
  'ster_dispatch_send',
  'ster_dispatch_upload_documents_manifest'
);
