import { useMemo } from 'react';
import { Column } from '../../../components/InfoTable';
import { useTranslation } from '../../../../services/translation';
import { Box, Tooltip } from '@material-ui/core';

export default function StockDaysReportListConst() {
  const { t } = useTranslation();

  const columns = useMemo<Array<Column>>(
    () => [
      {
        id: 'sku',
        label: t('Product SKU'),
        style: {
          whiteSpace: 'nowrap',
          width: '150px'
        },
        noSort: true
      },
      {
        id: 'name',
        label: t('Name'),
        style: {
          whiteSpace: 'nowrap',
          width: '150px'
        },
        noSort: true,
        render: ({ name }: any) => {
          return (
            <Tooltip title={name}>
              <Box>{name.slice(0, 20)}</Box>
            </Tooltip>
          );
        }
      },
      {
        id: 'current_stock',
        label: t('Current Stock')
      },
      {
        id: 'valued_stock',
        label: t('Valued Stock'),
        noSort: true
      },
      {
        id: 'units_sold',
        label: t('Units Sold')
      },
      {
        id: 'stock_days',
        label: t('Stock Days')
      },
      {
        id: 'units_sold_stock_days',
        label: t('Units Sold Stock Days')
      },
      {
        id: 'total_sold',
        label: t('Total sold')
      }
    ],
    // eslint-disable-next-line
    [t]
  );

  return {
    columns
  };
}
